<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Documents"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
        @click="()=>{
            $emit('complete')
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div style="max-width: 100rem; margin: 0 auto;">
        <p class="plan-settings-instructions">
          Gather strategic, foundational documents for team reference.
        </p>
        <p class="plan-settings-instructions">
          Group them in categories. Click on the caret icon under Category and select the appropriate category or choose [New Category] and enter a name. Click Select File. Locate the file on your computer, select it and click Open. Then click Upload.
        </p>
        <p class="plan-settings-instructions" style="font-size: 1.2rem;">
          File formats: jpg, jpeg, png, gif, webp, pdf, doc, xis, ppt, pages, numbers, key<br/>
          File size limit: 10 MB
        </p>
      </div>

      <div class="setup-content category-section" style="max-width: 100rem; margin: 0 auto;">
        <div class="categories-container">
          <ul
            v-for="category in planDocumentCategories"
            :key="category.id.intID"
            class="category"
          >
            <li class="category__header">
              <div v-if="!category.editing">
                {{ category.name }}
                <template v-if="$store.getters.currentUser.isSuperUser">
                  <span class="id-value"> ({{ category.id.intID }})</span>
                </template>
              </div>
              <div v-else>
                <InputText v-model="category.newName" />
                <i
                  class="pi pi-check save-icon"
                  @click="saveEditedCategory(category)"
                ></i>
              </div>

              <i
                v-if="category.name !== 'Uncategorized'"
                :class="`pi pi-${
                  category.editing ? 'times' : 'pencil'
                } edit-icon`"
                @click="() => (category.editing = !category.editing)"
              ></i>
            </li>
            <li
              v-for="document in category.planDocuments"
              :key="document.id.intID"
              class="document"
            >
              <span class="document__title">
                {{ document.asset.name }}
                <template v-if="$store.getters.currentUser.isSuperUser">
                  <span class="id-value"> ({{ document.id.intID }})</span>
                </template>
              </span>

              <div class="document__icons">
                <a
                  class="pi pi-download"
                  target="_blank"
                  :href="document.asset.fileLocation"
                  download
                />
                <i
                  class="pi pi-trash delete-icon"
                  @click="(e) => deleteDocument(e, category, document.id.intID)"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="add-document d-flex p-jc-center">
          <div class="field">
            <label for="document-category-add">Category</label>
            <Dropdown
              id="document-category-add"
              el="name"
              v-model="newDocument.category"
              optionLabel="name"
              :options="categoriesOptions"
              dataKey="key"
              @change="() => (isSelectedCategory = true)"
              :disabled="!shouldAllowFormInput"
            />
          </div>

          <div
            class="field"
            v-if="newDocument.category.id.intID === 0 && isSelectedCategory"
          >
            <label for="new-category-name">New Category Name</label>
            <InputText
              id="new-category-name"
              class="new-category-name"
              v-model="newDocument.newCategoryName"
              :disabled="!shouldAllowFormInput"
            />
          </div>

          <label class="document-select-file" for="new-document">
            Select File
            <input type="file" id="new-document" @change="onFileUpload" hidden :disabled="!shouldAllowFormInput" />
          </label>
          <div class="field">
            <label for="document-name-add">File Name</label>
            <InputText id="document-name-add" v-model="newDocument.name" :disabled="!shouldAllowFormInput" />
          </div>
          <div class="upload-button" @click="uploadFile">
            <div>Upload</div>
            <i class="pi pi-upload"></i>
          </div>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import PlanDocument from '@/models/PlanDocument'
import PlanDocumentCategory from '@/models/PlanDocumentCategory'
import ID from '@/models/ID'
import {AlertMessageSeverity} from '@/models/AlertMessage'

Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('Dropdown', Dropdown)

export default Vue.extend({
  name: 'PlanSettingsDocuments',
  components: {
    ViewHeader,
    ViewMain,
  },
  data() {
    return {
      newDocument: {
        name: '',
        category: {
          name: '',
          id: {intID: 0, apiID: ''} as ID,
          orderIndex: 0,
          planDocuments: [] as PlanDocument[],
        } as PlanDocumentCategory,
        newCategoryName: '',
        file: null,
      },
      isSelectedCategory: false,
      privatePlanDocumentCategories: [] as PlanDocumentCategory[],
      shouldAllowFormInput: true,
    }
  },
  created() {
    this.setupCategories()
  },
  computed: {
    planDocumentCategories: {
      get(): PlanDocumentCategory[] {
      
        return this.privatePlanDocumentCategories.filter(
          (cat) => cat.planDocuments.length > 0
        )
      },
      set(value: PlanDocumentCategory[]) {
       
        this.privatePlanDocumentCategories = value
      },
    },
    categoriesOptions() {
      return JSON.parse(
        JSON.stringify((this as any).privatePlanDocumentCategories)
      ).map((el: any, i) => {
        return el.name === 'Uncategorized' ? {...el, name: '[ New Category ]', key: i} : {...el, key: i}
      })
    },
  },
  methods: {
    async setupCategories() {
      // await this.$store.dispatch('refreshCurrentPlan')
      this.$store.dispatch('documentToCategoriesForCurrentPlan')
      this.planDocumentCategories = this.$store.getters.currentPlan.planDocumentCategories
    },
    onFileUpload(e) {
      this.newDocument.file = e.target.files[0]
      this.newDocument.name = e.target.files[0].name
      e.target.value = ''
    },
    uploadFile() {
      // Prevent upload if no file is entered
      if(!this.newDocument.file){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please select a file.',
        })
        return
      }

      // Prevent upload if category ids not selected
      if(!this.isSelectedCategory){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please select a category.',
        })
        return
      }

      // Prevent upload if new category name is not completed
      if(this.isSelectedCategory && this.newDocument.category.id.intID === 0 && this.newDocument.newCategoryName === ''){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please provide a new category name.',
        })
        return
      }

      if(!this.shouldAllowFormInput){
        return
      }

      this.shouldAllowFormInput = false
      this.isSelectedCategory = false
      const file: any = this.newDocument
      const newFile = new FormData()
      newFile.append(
        'file',
        new File([file.file], file.name, {
          type: file?.file?.type,
          lastModified: file?.file?.lastModified,
        })
      )

      this.$store.getters.services.users
        .mediaAsset(newFile)
        .then((newAsset) => {
          const newPlanDocument = PlanDocument.fromResponseObject({
            asset: newAsset,
            plan: this.$store.getters.currentPlan.id.intID,
            tactics: [],
            categories: [this.newDocument.category],
          })
          this.$store.getters.services.documents
            .create(newPlanDocument)
            .then((newDbDocument) => {
              newPlanDocument.id = ID.fromResponseObject(
                newDbDocument.id,
                'plan_documents'
              )
              const shouldCreateNewCategory =
                !this.newDocument.category?.id?.intID
                
              if (shouldCreateNewCategory) {
                const newDocCategory = PlanDocumentCategory.fromResponseObject({
                  name: this.newDocument.newCategoryName,
                })
                newDocCategory.planDocuments.push(newPlanDocument)
                this.$store.getters.services.documents
                  .createCategory(newDocCategory, newPlanDocument)
                  .then((newDbCat) => {
                    const newCat = new PlanDocumentCategory(
                      newDbCat.name,
                      ID.fromResponseObject(
                        newDbCat.id,
                        'plan_document_categories'
                      ),
                      [newPlanDocument],
                      0
                    )

                    let lastItem: any = []
                    lastItem = this.privatePlanDocumentCategories.pop()
                    this.privatePlanDocumentCategories.push(newCat)
                    this.privatePlanDocumentCategories.push(lastItem)

                    this.shouldAllowFormInput = true
                  })
              } else {
                const categoryIndex = this.$store.getters.currentPlan.planDocumentCategories.findIndex(
                    (cat) => cat.id.intID === this.newDocument.category.id.intID
                  )
                if (categoryIndex === -1) {
                  const newCat = new PlanDocumentCategory('Uncategorized')
                  newCat.planDocuments.push(newPlanDocument)
                  this.$store.getters.currentPlan.planDocumentCategories.push(newCat)

                  // Add newCat to local categories
                  this.privatePlanDocumentCategories.push(newCat)
                } else {
                  this.$store.getters.currentPlan.planDocumentCategories[categoryIndex].planDocuments.push(newPlanDocument)

                  // Add newPlanDocument to local category
                  const localCategoryIndex = this.privatePlanDocumentCategories.findIndex(
                    (cat) => cat.id.intID === this.newDocument.category.id.intID
                  )
                  this.privatePlanDocumentCategories[localCategoryIndex].planDocuments.push(newPlanDocument)
                }

                this.shouldAllowFormInput = true
              }

              // Ensure new document (and possibly category) is available in AppStore
              // TODO: Consider revising: This ensures that new documents are displayed when this view is reloaded during the same session, but that should be possible by simply updating or replacing the planDocumentCategories in AppStore
              this.$store.dispatch('refreshCurrentPlan')

              this.newDocument = {
                name: '',
                category: {
                  name: '',
                  id: {intID: 0, apiID: ''} as ID,
                  orderIndex: 0,
                  planDocuments: [] as PlanDocument[],
                } as PlanDocumentCategory,
                newCategoryName: '',
                file: null,
              }
            })
        })
        .catch((response) => {
          let errorMessage =
            'There was an error uploading the file. Files must be smaller than 10MB. Please try again.'
          if (String(response).indexOf('413') > -1) {
            errorMessage =
              'Your document was not uploaded. Files must be smaller than 10MB.'
          }
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.warn,
            summary: errorMessage,
          })

          this.shouldAllowFormInput = true
        })
    },
    saveEditedCategory(category) {
      category.name = category.newName
      category.editing = false
      this.$store.getters.services.documents.updateCategory(category)
    },
    deleteDocument(e, category, documentId) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this document?`,
        target: e.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.documents.delete(documentId).then(() => {
            category.planDocuments = category.planDocuments.filter(
              (doc) => doc.id.intID !== documentId
            )
          })
          // TODO: also delete MediaAsset?
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    handleClickContinue() {
      this.$emit('setup-step-complete')
    },
  },
})
</script>