<template>
  <div class="setup-content intro p-d-flex p-flex-column p-jc-center p-ai-center">

    <div class="inner-container">
      <h1>Let's Get Planning</h1>
      <h2>Start by naming your plan after the business or brand you will be building the marketing calendar for.</h2>

      <div class="form-container">
        <div class="p-field">
          <label for="plan_name">Plan Name</label>
          <InputText
            id="plan_name"
            type="text"
            class="p-inputtext input"
            v-model="currentPlan.name"
          />
        </div>
        <Button
          @click="handleClickBegin"
          class="continue-button"
          label="Begin"
          :disabled="currentPlan.name === ''"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import InputText from 'primevue/inputtext'
import Plan from '@/models/Plan'
import {AlertMessageSeverity} from '@/models/AlertMessage'

Vue.component('InputText', InputText)

export default Vue.extend({
  name: 'SetupIntro',
  data: () => ({
    setupMode: 'full',
  }),
  computed: {
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
  },
  methods: {
    handleClickBegin() {
      if (this.currentPlan.name === '') {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.error,
          summary: 'Please enter a plan name.',
        })
        return
      }
      this.currentPlan.accountId = this.$store.getters.currentAccount.id

      this.$store.getters.services.plans.updatePlanName(this.currentPlan.id, this.currentPlan.name).then(
        (returnedPlan) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Plan name updated.',
            life: 3000,
          })

          // Update the plan in the store
          this.$store.commit('updateCurrentPlan', returnedPlan)
          this.$store.dispatch('refreshCurrentPlan').then(()=>{
              // Route to the returned plan
              this.$router.push(`/account/${this.$route.params.setupAccountId}/plan/${this.$route.params.setupPlanId}/calendar?route=onboarding`)

              // Archived - routing to next step of onboarding flow
              // this.$router.push(`/setup/full/${this.$route.params.setupAccountId}/${this.$route.params.setupPlanId}/default-channels`)
            },
            (error)=>{
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: 'Error loading updated plan.',
                detail: error,
              })
              this.$router.push(`/plans`)
            }
          )
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error changing plan name.',
            detail: error,
          })
        }
      )
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.setup-content.intro {
  width: 100vw;
  height: 100vh;

  .inner-container {
    max-width: 60rem;
    margin: 0 auto;
    text-align: center
  }
  h1 {
    @include font-normal;
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  h2 {
    @include font-light;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
  .p-field {
    margin-bottom: 4rem;
    text-align: left;
  }
  .p-inputtext {
    width: 100%;
  }
  button {
    font-size: 1.5rem;
  }
}
</style>
