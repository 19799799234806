<template>
  <div>
    <div
      class="setup-channel__trigger"
      :class="{
        'is-nested': channel.isNested,
        'is-lead': channel.isLead,
      }"
      v-if="channel.name !== initiativesChannelName"
    >
      <div class="setup-channel-simple-row">
        <span 
          v-if="isChannelReorderable"
          :class="`drag-icon drag-icon--channel ${channel.opened && 'hasFlag'}`">
          &#x2630;
        </span>

        <ColorPicker
          v-if="isChannelEditable"
          @colorSelected="
            (color, e) => {
              channel.uiColor = color
              handleChannelChangedField(e, channel)
            }
          "
          @triggerClicked="
            () => {
              forceColorPickerClosed = false
              channel.opened = true
            }
          "
          :selectedColor="channel.uiColor"
          :forcePickerClosed="forceColorPickerClosed"
        />

        <div class="setup-channel__title-container">
          <div class="setup-channel-row">
            <h3 class="setup-channel__title" v-if="!channel.editing">
              <template v-if="isLeadPlan && channel.isNested">
                {{ channel.abbreviatedPlanName }} > {{ channel.name }}
              </template>
              <template v-else>
                {{ channel.name }}
              </template>
              <template v-if="$store.getters.currentUser.isSuperUser">
                <span class="id-value"> ({{ channel.id.intID }})</span>
              </template>
            </h3>
            <InputText
              type="text"
              v-model="channel.editedChannelName"
              v-else
              @click="stopPropagation"
            />
            <div class="icons" v-show="channel.opened">
              <i
                v-if="channel.editing && canUserEdit"
                class="pi pi-check"
                @click="(e) => editChannel(e, channel, channelIndex)"
              />
              <i
                v-if="channel.editing && canUserEdit"
                class="pi pi-times"
                @click="
                  (e) => {
                    e.stopPropagation()
                    channel.editing = false
                  }
                "
              />
              <i
                v-else
                class="pi pi-pencil"
                @click="
                  (e) => {
                    e.stopPropagation()
                    channel.editing = true
                  }
                "
              />
              <i
                class="pi pi-trash delete-icon"
                @click="(e) => deleteChannel(e, channel.id.intID)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="setup-channel__additional-fields"
        v-if="isChannelEditable"
        v-show="channel.opened"
      >
        <Checkbox
          :id="`media-${channel.id.intID}`"
          v-model="channel.isPaidMedia"
          :binary="true"
          class="p-mr-2"
          @change="(e) => handleChannelChangedField(e, channel)"
        />
        <label :for="`media-${channel.id.intID}`" @click="stopPropagation"
          >Show Long-Running in Overstory
          <span
            v-tooltip.bottom="paidMediaHelpText"
            class="pi pi-question-circle tooltip-color"
          >
          </span
        ></label>
        <Checkbox
          :id="`hide-tactics-${channel.id.intID}`"
          v-model="channel.enableTacticFlagging"
          :binary="true"
          class="p-mr-2"
          @change="(e) => handleChannelChangedField(e, channel)"
        />
        <label
          :for="`hide-tactics-${channel.id.intID}`"
          @click="stopPropagation"
          >Hide Tactics in Flow View
          <span
            v-tooltip.bottom="hideInFlowViewHelpText"
            class="pi pi-question-circle tooltip-color"
          ></span
        ></label>
        <Checkbox
          :id="`focus-${channel.id.intID}`"
          v-model="channel.useMonthlyFocusType"
          :binary="true"
          class="p-mr-2"
          @change="(e) => handleChannelChangedMonthlyFocus(e, channel)"
        />
        <label 
          :for="`focus-${channel.id.intID}`" 
          @click="stopPropagation"
          >Monthly Channel Focus
          <span
            v-tooltip.bottom="monthlyFocusHelpText"
            class="pi pi-question-circle tooltip-color"
          ></span>
        </label>
      </div>

      <span
        v-if="isChannelEditable"
        @click="
          () => {
            if (isChannelEditable) {
              channel.opened = !channel.opened
              forceColorPickerClosed = !channel.opened
            }
          }
        "
        :class="`setup-channel__arrow pi pi-chevron-${
          channel.opened ? 'down' : 'right'
        }`"
      ></span>
    </div>
    <div v-if="channel.opened" class="dropdown-content-channel">
      <div class="channel-export-btn">
        <!-- BEGIN: CSV buttons hidden for beta - JV 04.29.22 -->
        <!-- <Button
          class="onboarding-button-style"
          @click="(e) => handleChannelExport(e, channel)"
          label="Export Channel to CSV"
          disabled
        /> -->
        <!-- END: CSV buttons hidden for beta - JV 04.29.22 -->
      </div>
      <div>
        <Container
          class="tactic-types-container"
          @drop="(dropResults) => onTacticTypeDrop(dropResults, channel)"
          drag-handle-selector=".drag-icon--tactic-type"
        >
          <Draggable
            class="setup-tactic-type custom-tactic-type"
            v-for="(tacticType, tacticTypeIndex) in getFilteredTypesList(
              channel.tacticTypes
            )"
            :key="tacticType.id.intID"
          >
            <!-- Removing ordering functionality for types - JV 07.12.22 -->
            <!-- <span class="drag-icon drag-icon--tactic-type">&#x2630;</span> -->

            <div
              class="setup-tactic-type__trigger"
              @click="() => (tacticType.opened = !tacticType.opened)"
            >
              <!-- <span
                class="setup-tactic-type__trigger__uptitle"
                v-if="tacticType.opened"
              >
                Tactic Type
                <span
                  v-tooltip.bottom="tacticTypeHelpText"
                  class="pi pi-question-circle tooltip-color"
                ></span
              ></span> -->
              <div
                class="setup-tactic-type__trigger__title"
                v-if="!tacticType.editing"
              >
                {{ tacticType.name }}
                <template v-if="$store.getters.currentUser.isSuperUser">
                  <span class="id-value"> ({{ tacticType.id.intID }})</span>
                </template>
              </div>
              <InputText
                type="text"
                v-model="tacticType.editedTacticTypeName"
                v-else
                @click="stopPropagation"
              />
              <div class="setup-tactic-type__trigger__action-buttons">
                <div class="icons" v-if="tacticType.opened">
                  <i
                    v-if="tacticType.editing"
                    class="pi pi-check"
                    @click="
                      (e) => editTacticType(e, tacticType, tacticTypeIndex)
                    "
                  />
                  <i
                    v-if="tacticType.editing"
                    class="pi pi-times"
                    @click="
                      (e) => {
                        e.stopPropagation()
                        tacticType.editing = false
                      }
                    "
                  />
                  <i
                    v-else
                    class="pi pi-pencil"
                    @click="
                      (e) => {
                        e.stopPropagation()
                        tacticType.editing = true
                      }
                    "
                  />
                  <i
                    class="pi pi-trash delete-icon"
                    @click="(e) => deleteTacticType(e, tacticType, channel)"
                  />
                </div>

                <div class="buttons" v-if="tacticType.opened">
                  <!-- BEGIN: Archiving TacticImport.vue - JV 04.18.23 -->
                  <!-- <Button
                    @click="(e) => handleImportFromCSV(e)"
                    label="Import from CSV"
                  /> -->
                  <!-- <Button
                    class="onboarding-button-style"
                    @click="(e) => handleExportToCSV(e, channel, tactic)"
                    label="Export to CSV"
                    disabled
                  /> -->
                  <!-- END: Archiving TacticImport.vue - JV 04.18.23 -->
                </div>
              </div>

              <span
                :class="`setup-tactic-type__arrow pi pi-chevron-${
                  tacticType.opened ? 'down' : 'right'
                }`"
              ></span>
            </div>

            <div :class="`dropdown-content ${tacticType.opened && 'isOpen'}`">
              <ul class="platforms">
                <li class="platforms__title">
                  Platforms
                  <span
                    v-tooltip.bottom="platformsHelpText"
                    class="pi pi-question-circle tooltip-color"
                  ></span>
                </li>
                <li
                  v-for="platform in tacticType.tacticPlatforms"
                  :key="platform.key"
                >
                  <div class="platform">
                    <span>
                      {{ platform.name }}
                      <template v-if="$store.getters.currentUser.isSuperUser">
                        <span class="id-value"> ({{ platform.id.intID }})</span>
                      </template>
                    </span>
                    <i
                      class="pi pi-trash delete-icon"
                      @click="
                        (e) =>
                          removePlatformFromTacticType(
                            tacticType,
                            platform.id.intID
                          )
                      "
                    />
                  </div>
                </li>
              </ul>
              <div class="add-field add-field--platform">
                <div class="p-d-flex">
                  <Checkbox
                    id="tacticTypeMultiplePlatforms"
                    v-model="tacticType.shouldTacticsAllowMultiplePlatforms"
                    :binary="true"
                    class="p-mr-3"
                    @change="
                      () =>
                        updateTacticTypeAllowMultiplePlatforms(
                          tacticType.shouldTacticsAllowMultiplePlatforms,
                          tacticType
                        )
                    "
                  />
                  <label for="tacticTypeMultiplePlatforms"
                    >Allow tactics to have multiple platforms</label
                  >
                </div>
                <div>
                  <div class="p-d-flex">
                    <MultiSelect
                      v-model="tacticType.newPlatforms"
                      :options="filteredPlatformsForType(tacticType)"
                      placeholder="Select Platforms"
                      optionLabel="name"
                      dataKey="key"
                      class="p-mr-3"
                    />
                    <div
                      class="add-field__btn"
                      @click="() => updateTacticTypePlatforms(tacticType)"
                    >
                      <span class="pi pi-plus-circle"></span>
                      <span>Platform</span>
                    </div>
                  </div>
                  <div class="p-d-flex p-mt-3">
                    <InputText
                      v-model="tacticType.newPlatform"
                      placeholder="New Platform"
                      class="p-mr-3"
                    />
                    <div
                      class="add-field__btn"
                      @click="() => createPlatformForTacticType(tacticType)"
                    >
                      <span class="pi pi-plus-circle"></span>
                      <span>New Platform</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="customFields">
                <div class="customFields__header">
                  <div class="customFields__header__title">
                    Custom Fields
                    <span
                      v-tooltip.bottom="customFieldsHelpText"
                      class="pi pi-question-circle tooltip-color"
                    ></span>
                  </div>
                  <div class="header-fields">
                    <div class="name">Name</div>
                    <div class="type">Type</div>
                  </div>
                </div>
                <Container
                  @drop="
                    (dropResult) => onCustomFieldsDrop(dropResult, tacticType)
                  "
                  drag-handle-selector=".drag-icon--custom-field"
                >
                  <Draggable
                    v-for="(field, fieldIndex) in tacticType.tacticFields"
                    :key="field.key"
                  >
                    <div class="tactic-field">
                      <!-- Removing ordering functionality for fields - JV 07.12.22 -->
                      <!-- <span class="drag-icon drag-icon--custom-field"
                        >&#x2630;</span
                      > -->
                      <div class="name">
                        <span v-if="!field.editing">
                          {{ field.name }}
                        </span>
                        <InputText
                          type="text"
                          v-model="field.editedTacticFieldFields.name"
                          v-else
                        />
                      </div>
                      <div class="type">
                        <span v-if="!field.editing">
                          {{ fieldOptions[field.type] }}
                        </span>
                        <Dropdown
                          v-else
                          v-model="field.editedTacticFieldFields.type"
                          :options="types"
                          placeholder="Type"
                          optionLabel="label"
                        />
                      </div>
                      <div class="icons">
                        <i
                          v-if="field.editing"
                          class="pi pi-check"
                          @click="() => editCustomField(field, fieldIndex)"
                        />
                        <i
                          v-if="field.editing"
                          class="pi pi-times"
                          @click="
                            () => {
                              field.editing = false
                            }
                          "
                        />
                        <i
                          v-else
                          class="pi pi-pencil"
                          @click="
                            () => {
                              field.editing = true
                            }
                          "
                        />
                        <i
                          class="pi pi-trash delete-icon"
                          @click="
                            (e) => deleteCustomField(e, field.id.intID, tacticType)
                          "
                        />
                      </div>
                    </div>
                  </Draggable>
                </Container>

                <div>
                  <div class="add-field add-field--type">
                    <div class="name">
                      <InputText
                        v-model="tacticType.newTacticField.name"
                        placeholder="New Custom Field"
                      />
                    </div>
                    <div class="type">
                      <Dropdown
                        v-model="tacticType.newTacticField.type"
                        :options="types"
                        placeholder="Type"
                        optionLabel="label"
                      />
                    </div>
                    <div
                      class="add-field__btn"
                      @click="
                        () =>
                          addTacticField(
                            tacticType.newTacticField,
                            channel,
                            tacticType
                          )
                      "
                    >
                      <span class="pi pi-plus-circle"></span>
                      <span>Custom Field</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
          <div class="add-field add-field--tactic-type">
            <div class="p-d-flex p-ai-center">
              <InputText
                v-model="channel.newTacticName"
                placeholder="New Tactic Type"
                class="p-mr-3"
              />
              <span
                v-tooltip.bottom="tacticTypeHelpText"
                class="pi pi-question-circle tooltip-color"
              ></span>
            </div>

            <div class="add-field__btn" @click="() => addTacticType(channel)">
              <span class="pi pi-plus-circle"></span>
              <span>Tactic Type</span>
            </div>
          </div>
        </Container>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'

import Tooltip from 'primevue/tooltip'
import TacticType from '@/models/TacticType'
import TacticPlatform from '@/models/TacticPlatform'
import TacticField, {TacticFieldType} from '@/models/TacticField'
import Plan from '@/models/Plan'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Channel from '@/models/Channel'
import ID from '@/models/ID'
import {applyDrag} from '@/utils/applyDrag'

import {Container, Draggable} from 'vue-smooth-dnd'
import ColorPicker from '@/components/ColorPicker.vue'
import isThisHour from 'date-fns/isThisHour'

Vue.component('Button', Button)
Vue.component('Checkbox', Checkbox)
Vue.component('Dropdown', Dropdown)
Vue.component('InputText', InputText)

const fieldOptions = {
  string: 'Text',
  number: 'Number',
  url: 'Link',
  image: 'Image',
  richtext: 'Rich Text',
}

export default Vue.extend({
  name: 'PlanSettingsChannelDetail',
  props: {
    channel: {
      type: Object,
      required: true,
    },
    channelIndex: {
      type: Number,
      required: true,
    },
    canUserEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    ColorPicker,
    Container,
    Draggable,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => ({
    channels: [] as Channel[],
    updatingChannel: false,
    forceColorPickerClosed: false,
    paidMediaHelpText:
      "Multi-week and multi-month Tactics can clutter up the day grid in the Day and Month calendar views. Activate this feature to show all Tactics with a duration over 21 days in the Long-Running module in the Overstory of these views.",
    hideInFlowViewHelpText:
      "The Flow view was designed to show a high level multi month overview of marketing plans. For Channels, such as Social Media, with multiple tactics per week or day, this feature gives you the ability to hide the Channel's tactics in the Flow view. These tactics will still be viewable in the Day, Month, and Table views. VIP tactics can be flagged in the Tactic Detail so they are still seen in the Flow view. <br/><br/>When choosing to hide tactics in a Channel, we suggest adding a Monthly Channel Focus to provide a higher level summary of activity in the Flow view.",
    monthlyFocusHelpText:
      'A Monthly Channel Focus can be added to a Channel to serve as a planning prompt or summary of activity in the Overstory of the Day and Month views and within the Flow view.',
    tacticTypeHelpText:
      'Tactic Types are used to differentiate various marketing vehicles used within a marketing channel.',
    platformsHelpText:
      'Platforms are the third party media, publishing and advertising companies such as Instagram, The New York Times, and Clear Channel Outdoor, that you use to promote your brand. Once entered, a Platform can be accessed in any Channel from a dropdown list.',
    customFieldsHelpText:
      'Beyond the default data associated with each Tactic, you can use Custom Fields to add additional information in the form of text, a number, an image, or a URL.',
    initiativesChannelName: Channel.CHANNEL_NAME_INITIATIVES as string,
  }),
  computed: {
    types(): {} {
      return Object.keys(TacticFieldType).map((key) => {
        return {
          code: key,
          label: fieldOptions[key],
        }
      })
    },
    fieldOptions(): {} {
      return fieldOptions
    },
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    platforms(): string[] {
      return this.$store.getters.socialTacticPlatformNamesDict
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    isNestedPlan(): boolean {
      return this.$store.getters.currentPlan.parentId.intID !== 0 ? true : false
    },
    isChannelEditable(): boolean {
      return (
        this.canUserEdit &&
        (
          (this.isLeadPlan && !this.channel.isNested) ||
          (!this.isLeadPlan && this.channel.isNested) ||
          (!this.channel.isLead && !this.channel.isNested)
        )
      )
    },
    isChannelReorderable(): boolean {
      return (
        this.canUserEdit &&
        (
          this.isLeadPlan ||
          (!this.isLeadPlan && !this.isNestedPlan)
        )
      )
    }
  },
  created() {
    this.updateLocalChanels(this.currentPlan.channels)
  },
  methods: {
    // TODO: updateLocalChanels and this.channels should be removed from this component and channel.tacticTypes should be sorted in AppStore or on mount of this component
    updateLocalChanels(channels: Channel[]) {
      this.channels = channels
        .filter((c) => c.isVisibleCustomChannel)
        .map((channel) => {
          channel.planId = this.currentPlan.id
          channel.tacticTypes = channel.tacticTypes
            .map((tacticType) => {
              tacticType.tacticFields = tacticType.tacticFields.sort(
                (a, b) => a.orderIndex - b.orderIndex
              )
              return tacticType
            })
            .sort((a, b) => a.orderIndex - b.orderIndex)
          return channel
        })
        .sort((a, b) => a.orderIndex - b.orderIndex)
    },
    getFilteredTypesList(types: TacticType[]) {
      return types.filter(
        (t) => t.isVisibleCustomTacticType && t.name.toLowerCase() !== this.$store.getters.monthlyFocusTypeName
      )
    },
    updateTacticTypeAllowMultiplePlatforms(
      newValue: boolean,
      tacticType: TacticType
    ) {
      tacticType.shouldTacticsAllowMultiplePlatforms = newValue
      this.$store.getters.services.channels.updateTacticType(tacticType)
    },
    updateTacticTypePlatforms(tacticType) {
      if (!tacticType.newPlatforms) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must select platforms first',
        })
        return
      }

      tacticType.tacticPlatforms = tacticType.tacticPlatforms.concat(
        tacticType.newPlatforms
      )
      this.$store.getters.services.channels
        .updateTacticType(tacticType)
        .then(() => {
          tacticType.newPlatforms = []
        })
    },
    createPlatformForTacticType(type: TacticType) {
      if (type.newPlatform === '') {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please enter a platform name.',
        })
        return
      }

      const newPlatform = new TacticPlatform(
        new ID(),
        this.$store.getters.currentUser?.id,
        type.newPlatform,
        [this.currentPlan?.id],
        this.channel.id
      )

      this.$store.getters.services.channels
        .createTacticPlatform(newPlatform)
        .then(
          (returnedPlatform) => {
            type.tacticPlatforms.push(returnedPlatform)
            this.$store.getters.services.channels.updateTacticType(type).then(
              (returnedType) => {
                type.newPlatform = ''
                this.$store.dispatch('refreshCurrentPlan').then(() => {
                  this.channel.opened = true
                  const typeInChannel = this.channel.tacticTypes.find(
                    (type) => type.id.intID === returnedType.id.intID
                  )
                  if (typeInChannel) {
                    typeInChannel.opened = true
                  }
                })
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.warn,
                  summary: `Error adding new platform to ${type.name}.`,
                  detail: error,
                })
              }
            )
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.warn,
              summary: 'Error creating new platform.',
              detail: error,
            })
          }
        )
    },
    filteredPlatformsForType(tacticType) {
      return this.$store.getters.currentTacticPlatforms.filter((platform) => {
        if (
          tacticType.tacticPlatforms.find(
            (currentPlatform) => currentPlatform.id.intID === platform.id.intID
          )
        ) {
          return false
        }
        
        // Removing lead/nested logic so all platforms appear in nested plans - JV 12.01.23
        // return (this.isLeadPlan && platform.isLead) || (!this.isLeadPlan && platform.isNested) || (!platform.isLead && !platform.isNested)

        return true
      })
    },
    removePlatformFromTacticType(tacticType: TacticType, platformId: number) {
      tacticType.tacticPlatforms = tacticType.tacticPlatforms.filter(
        (platform) => platform.id.intID !== platformId
      )
      this.$store.getters.services.channels.updateTacticType(tacticType)
    },
    deleteChannel(e, channelId: number) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this Channel? All associated Tactics will be removed from the calendar.`,
        target: e.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.channels.delete([channelId]).then(() => {
            const newPlan = (this as any).currentPlan
            newPlan.channels = newPlan.channels.filter(
              (channel) => channel.id.intID !== channelId
            )

            this.$store.commit('updateCurrentPlan', newPlan)
            this.updateLocalChanels(newPlan.channels)
          })
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    editChannel(e, channel: Channel, channelIndex: number) {
      if (e) {
        e.stopPropagation()
      }
      channel.name = channel.editedChannelName
      channel.orderIndex = channelIndex

      this.$store.getters.services.channels.update(channel).then(() => {
        channel.editing = false
      })
    },
    handleChannelChangedField(e, channel: Channel) {
      e.stopPropagation()

      if (!this.updatingChannel) {
        const openedChannelIntIds = this.currentPlan.channels
          .filter((channel) => channel.opened)
          .map((channel) => channel.id.intID)
        this.updatingChannel = true
        setTimeout(() => {
          this.$store.getters.services.channels.update(channel).then(() => {
            this.updatingChannel = false
            this.$store.dispatch('refreshCurrentPlan', openedChannelIntIds)
          })
        }, 400)
      }
    },
    addMonthlyFocusType(channel) {
      // Create monthly focus type for channel if it does not exist
      const thisMonthlyFocusTacticType = channel.tacticTypes.find(
        (type) =>
          type.name.toLowerCase() === this.$store.getters.monthlyFocusTypeName
      )

      if (!thisMonthlyFocusTacticType) {
        const newMonthlyFocusType = new TacticType()
        newMonthlyFocusType.name = 'Monthly Focus'
        newMonthlyFocusType.creatorId = this.$store.getters.currentUser.id
        newMonthlyFocusType.channelId = channel.id
        newMonthlyFocusType.isVisibleCustomTacticType = false

        this.$store.getters.services.channels
          .createTacticType(newMonthlyFocusType)
          .then(
            (returnedType) => {
              // Add new type to current channel and make API call to update channel
              channel.tacticTypes.push(returnedType)
              this.$store.getters.services.channels.update(channel).then(
                () => {
                  // Success
                },
                (error) => {
                  Vue.prototype.$toast.add({
                    severity: AlertMessageSeverity.error,
                    summary: `Error enabling Monthly Channel Focus for ${channel.name}.`,
                    life: 3000,
                  })
                }
              )
            },
            () => {
              channel.useMonthlyFocusType = false
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: `Error creating Monthly Channel Focus type for ${channel.name}.`,
                life: 3000,
              })
            }
          )
      } else {
        this.$store.getters.services.channels.update(channel).then(
          () => {
            // Success
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: `Error enabling Monthly Channel Focus for ${channel.name}.`,
              life: 3000,
            })
          }
        )
      }
    },
    removeMonthlyFocusType(channel: Channel) {
      this.$store.getters.services.channels.update(channel).then(
        () => {
          // Success
        },
        (error) => {
          channel.useMonthlyFocusType = true
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: `Error disabling Monthly Channel Focus for ${channel.name}.`,
            life: 3000,
          })
        }
      )
    },
    handleChannelChangedMonthlyFocus(e, channel: Channel) {
      e.stopPropagation()
      if (e.target.checked) {
        this.addMonthlyFocusType(channel)
      } else {
        this.removeMonthlyFocusType(channel)
      }
    },
    handleExportToCSV(e, channel: Channel, type: TacticType) {
      e.stopPropagation()
      this.$store.getters.services.channels
        .exportChannel(channel.id, [type.id], 'csv')
        .then(
          (response) => {
            const link = document.createElement('a')
            link.download = `AnnumExport-${type.name
              .toLowerCase()
              .replaceAll(' ', '-')}.zip`
            link.href = response.url
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.warn,
              summary: 'Error exporting tactics.',
              detail: error,
            })
          }
        )
    },
    onCustomFieldsDrop(dropResult, tacticType: TacticType) {
      tacticType.tacticFields = applyDrag(tacticType.tacticFields, dropResult)
      tacticType.tacticFields = tacticType.tacticFields.map((el, i) => {
        el.orderIndex = i
        this.editCustomField(el, i)
        return el
      })

      this.$store.getters.services.channels.updateTacticType(tacticType).then(
        () => {
          // Success
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.warn,
            summary: 'Error updating custom fields.',
            detail: error,
          })
        }
      )
    },
    editCustomField(field: TacticField, fieldIndex: number) {
      field.name = field.editedTacticFieldFields.name
      field.type = field.editedTacticFieldFields.type.code
      field.description = field.editedTacticFieldFields.description
      field.helpText = field.editedTacticFieldFields.helpText
      field.orderIndex = fieldIndex

      this.$store.getters.services.channels
        .updateTacticField(field)
        .then(() => {
          field.editing = false
        })
    },
    deleteCustomField(e, fieldId, tacticType) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this Custom Field? All associated data related with this field will be removed from Tactics.`,
        target: e.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.channels
            .deleteTacticField(fieldId)
            .then(() => {
              tacticType.tacticFields = tacticType.tacticFields.filter(
                (field) => field.id.intID !== fieldId
              )

              this.$store.getters.services.channels
                .updateTacticType(tacticType)
                .then(() => {
                  // Success
                })
                .catch((error) =>
                  Vue.prototype.$toast.add({
                    severity: AlertMessageSeverity.warn,
                    summary: 'Error removing custom field from tactic type.',
                    detail: error,
                  })
                )
            })
            .catch((error) =>
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.warn,
                summary: 'Error deleting custom field.',
                detail: error,
              })
            )
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    addTacticField(newField: any, channel: Channel, tacticType: TacticType) {
      if (!newField.name) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must give a name to the field first',
        })
        return
      }
      if (!newField.type) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must select a type',
        })
        return
      }

      const newTacticField = new TacticField(
        new ID(),
        (this as any).$store.getters.currentUser?.id,
        newField.name,
        newField.type.code,
        newField.description,
        newField.helpText,
        [],
        tacticType.tacticFields ? tacticType.tacticFields.length : 0
      )

      this.$store.getters.services.channels
        .createTacticField(newTacticField)
        .then((returnedTacticField) => {
          tacticType.tacticFields.push(returnedTacticField)

          this.$store.getters.services.channels
            .updateTacticType(tacticType)
            .then(() => {
              tacticType.newTacticField = {
                name: '',
                type: '',
                description: '',
                helpText: '',
              }

              channel.opened = true
              tacticType.opened = true

              const refreshCommandString = 'refreshCurrentPlan'
              this.$store
                .dispatch(refreshCommandString)
                .then((returnedPlan) => {
                  const returnedChannel = returnedPlan.channels.find(
                    (thisChannel) => thisChannel.id.intID === channel.id.intID
                  )
                  if (returnedChannel) {
                    returnedChannel.opened = true
                    const returnedType = returnedChannel.tacticTypes.find(
                      (thisType) => thisType.id.intID === tacticType.id.intID
                    )
                    returnedType.opened = true
                  }
                })
            })
        })
    },
    addTacticType(channel: Channel) {
      if (!channel.newTacticName) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must enter a tactic name first',
        })
        return
      }

      const tacticName = channel.newTacticName
      if (
        tacticName.toLowerCase() === this.$store.getters.monthlyFocusTypeName
      ) {
        this.addMonthlyFocusType(channel)
      } else {
        const tacticType = new TacticType(
          new ID(),
          (this as any).$store.getters.currentUser?.id,
          tacticName,
          '',
          [],
          [],
          channel.id
        )
        tacticType.isVisibleCustomTacticType = true
        tacticType.orderIndex = channel.tacticTypes.length

        this.$store.getters.services.channels.createTacticType(tacticType).then(
          (newTacticType) => {
            channel.newTacticName = ''
            channel.tacticTypes.push(newTacticType)

            this.$store.commit('updateCurrentPlan', this.currentPlan)
            this.updateLocalChanels(this.currentPlan.channels)
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.warn,
              summary: 'Error creating tactic type.',
              detail: error,
            })
          }
        )
      }
    },
    editTacticType(e, tactic: TacticType, tacticIndex: number) {
      if (e) {
        e.stopPropagation()
      }
      tactic.name = tactic.editedTacticTypeName
      tactic.orderIndex = tacticIndex
      this.$store.getters.services.channels
        .updateTacticType(tactic)
        .then(() => {
          tactic.editing = false
        })
    },
    deleteTacticType(e, tacticType: TacticType, channel: Channel) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this Tactic Type? All associated Tactics will be removed from the calendar.`,
        target: e.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.channels
            .deleteTacticType(tacticType.id.intID)
            .then(
              () => {
                channel.tacticTypes = channel.tacticTypes.filter(
                  (thisType) => thisType.id.intID !== tacticType.id.intID
                )

                // TODO: stop API loading spinner
                this.updateLocalChanels(this.currentPlan.channels)
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.warn,
                  summary: 'Error in deleting tactic type.',
                  detail: error,
                })
              }
            )
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    onTacticTypeDrop(dropResult, channel: Channel) {
      channel.tacticTypes = applyDrag(channel.tacticTypes, dropResult)
      channel.tacticTypes = channel.tacticTypes.map((el, i) => {
        el.orderIndex = i
        this.editTacticType('', el, i)
        return el
      })
      this.$store.getters.services.channels.update(channel).then(
        () => {
          // Success
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.warn,
            summary: 'Error reordering tactic type.',
            detail: error,
          })
        }
      )
    },
    handleChannelExport(e, channel: Channel) {
      e.stopPropagation()
      this.$store.getters.services.channels
        .exportChannel(
          channel.id,
          channel.tacticTypes.map((t) => t.id),
          'csv'
        )
        .then(
          (response) => {
            const link = document.createElement('a')
            link.download = `AnnumExport-${channel.name}.zip`
            link.href = response.url
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          },
          (error) => {
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.warn,
              summary: 'Error exporting tactics.',
              detail: error,
            })
          }
        )
    },
    stopPropagation(e) {
      e.stopPropagation()
    },
  },
})
</script>