<template>
  <div
    class="logged-out-container p-d-flex p-flex-column p-jc-center p-ai-center"
  >
    <div class="maintenance-message">
      <img
        class="annum-logo"
        alt="Annum logo"
        src="@/assets/logo-global.jpeg"
        width="200"
        height="86"
      />
      <h1>Under Maintenance</h1>
      <p>We are behind the scenes working to make things better. We'll be back up and running soon.</p>
      <p>If you have any questions or issues contact <a href="mailto:support@annumplanning.com">support@annumplanning.com</a></p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'MaintenanceMode',
})
</script>

<style lang="scss">
.logged-out-container {
  margin-top: 2rem;
}
.maintenance-message {
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 5rem;
    margin: 0.5em auto 0.75em;
    font-variation-settings: "wght" 400;
  }
  p {
    font-size: 2rem;
    margin-bottom: 2em;
  }
}
</style>