import {rrulestr} from 'rrule'
import Tag from './Tag'
import TacticFieldValue from './TacticFieldValue'
import TacticType from './TacticType'
import TacticPlatform from './TacticPlatform'
import ID from './ID'
import PlanDocument from './PlanDocument'
import isValidDate from '@/utils/isValidDate'
import Channel from './Channel'
import RelatedInitiativeMapping from './RelatedInitiativeMapping'

export default class Tactic {
  public editing = false
  public opened = false
  public newTitle: string
  public newStartDate: Date
  public newEndDate: Date
  public newFile: any
  public newFileName: string
  public newSummary: string

  constructor(
    public id: ID = new ID(),
    public creatorId: ID = new ID(),
    public title: string = '',
    public startDate: Date = new Date(Date.now()),
    public endDate: Date = new Date(Date.now()),
    public tacticTypeId: ID = new ID(),
    public estimatedCost: number = 0,
    public actualCost: number = 0,
    public impressions: number = 0,
    public ratingPoints: number = 0,
    public tagIds: ID[] = [],
    public typeValues: TacticFieldValue[] = [],
    public relatedInitiatives: RelatedInitiativeMapping[] = [],
    public isFlagged: boolean = false,
    public planId: ID = new ID(),
    public isAllDay: boolean = false,
    public repeats: boolean = false,
    public recurrenceEndDate: Date | null = null,
    public isCustomRecurrence: boolean = false,
    public recurrenceText: string = '',
    public rrule?: string | null, // optional so we can delete it before inserting in calendar if null
    public tacticPlatformIds: ID[] = [],
    public isNested: boolean = false,
    public isLead: boolean = false,
    public documentIds: ID[] = [],
    public localIsInitiative: boolean = false,
    public summary: string = '',
    public isUnscheduled: boolean = false,

    // public socialAliasNames: string[] = [], // Populated on front-end // Tactic alias system no longer used - JV 05.24.22
    public channelName: string = '', // Populated on front-end
    public tacticTypeName: string = '', // Populated on front-end
    public isSubscriptionEvent: boolean = false, // Populated on front-end
    public abbreviatedPlanName: string = '', // Populated on front-end
    public tags: Tag[] = [], // Populated on front-end
    public tacticPlatforms: TacticPlatform[] = [], // Populated on front-end
    public documents: PlanDocument[] = [], // Populated on front-end
    public originalTacticID: ID | null = null, // Populated on front-end
    public isRecurringEventClone: boolean = false, // Populated on front-end
  ) {
    this.newTitle = title
    this.newStartDate = startDate
    this.newEndDate = endDate
    this.newFile = null
    this.newFileName = ''
    this.newSummary = summary
  }

  public get key() {
    return 'tactic-' + this.id.intID.toString()
  }

  public get tagCategories() {
    const categories: any = [
      {
        id: 1,
        label: 'Objective',
        name: 'Objective',
        tags: [],
      },
      {
        id: 2,
        label: 'Target Segment',
        name: 'Target Segment',
        tags: [],
      },
      {
        id: 3,
        label: 'Journey Phase',
        name: 'Journey Phase',
        tags: [],
      },
      {
        id: 4,
        label: 'Custom',
        name: 'Custom',
        tags: [],
      },
    ]

    this.tags.forEach((tag) => {
      const [category, name] = tag.text.split(':')
      const categoryIndex = categories.findIndex(
        (cat) => cat.label === category
      )

      const newTag = {
        id: tag.id.intID,
        label: name ? name : category,
        editing: false,
        newName: name ? name : category,
      }

      if (categoryIndex === -1) {
        categories[3].label = category
        categories[3].newName = category
      }
      categories[categoryIndex === -1 ? 3 : categoryIndex].tags.push(newTag)
    })

    return categories
  }

  public get startDateString(): string {
    return this.startDate.toLocaleDateString()
  }

  public get endDateString(): string {
    return this.endDate.toLocaleDateString()
  }

  public get start(): Date {
    return this.startDate
  }

  public get end(): Date {
    if (this.isAllDay) {
      // Add one day to end date of all day events to resolve confusing issue (https://github.com/fullcalendar/fullcalendar/issues/2909)
      const modifiedDate = new Date(this.endDate.getTime())
      modifiedDate.setDate(modifiedDate.getDate() + 1)
      return modifiedDate
    }
    return this.endDate
  }

  public get allDay(): boolean {
    return this.isAllDay
  }

  public get tacticPlatformName(): string {
    let returnString = ''
    if (this.tacticPlatforms && this.tacticPlatforms.length) {
      this.tacticPlatforms.forEach((platform)=>{
        returnString += platform.name + ' '
      })
    }
    return returnString
  }

  public get isInitiative(): boolean {
    if(this.localIsInitiative == true){
      return true
    }
    if(this.channelName !== '' && this.channelName === Channel.CHANNEL_NAME_INITIATIVES){
      return true
    }
    return false
  }

  public set isInitiative(value: boolean) {
    this.localIsInitiative = value
  }

  public get tacticPlatformNames() {
    return this.tacticPlatforms?.map((platform)=>platform.name).join(', ')
  }

  public get duration(): number | object {
    const datesDifference = this.endDate.getTime() - this.startDate.getTime()
    if(datesDifference === 0){
      // Return one day if start and end date are equal
      if(this.isAllDay){
        return 86400000 // full day
      }
      return 60000 // one hour
    }
    if(this.isAllDay){
      // Add one day to the difference for all day tactics to work around exact 24-hour difference equaling one day
      return {
        days: Math.ceil(datesDifference / 86400000) + 1
      }
    }
    return datesDifference
  }

  private getFormattedRecurranceString() {
    if(this.rrule){
      return this.rrule.replace('\nRRULE:',';').replace('DTSTART:', 'DTSTART=')
    }
    return ``
  }

  public static fromResponseObject(obj): Tactic {
    if (!obj) return new Tactic()

    const tagIds = [] as ID[]
    if (obj.tags) {
      obj.tags.forEach((element) => {
        tagIds.push(ID.fromResponseObject(element, 'tags'))
      })
    }

    const typeValues = [] as TacticFieldValue[]
    if (obj.typeValues) {
      obj.typeValues.forEach((element) => {
        typeValues.push(TacticFieldValue.fromResponseObject(element))
      })
    }

    let startDateString = obj.startDate
    if (obj.startDate && obj.startDate.date) {
      startDateString = obj.startDate.date
    }

    let endDateString = obj.endDate
    if (obj.endDate && obj.endDate.date) {
      endDateString = obj.endDate.date
    }

    // Handle recurring tactic properties
    let recurrenceEndDate: Date|null = null
    let recurrenceText = ''
    if(obj.recurrence){
      // Remove '\nRRULE:' from rrule in case legacy tactic data contains this malformatted strings
      obj.recurrence = obj.recurrence.replace('\nRRULE:',';')

      // Set up recurrence end date
      if(obj.recurrenceEndDate){
        // Create recurrence end date
        recurrenceEndDate = new Date(obj.recurrenceEndDate)
        const timezoneOffset = new Date().getTimezoneOffset()
        recurrenceEndDate.setHours(recurrenceEndDate.getHours() - (timezoneOffset / 60))

        // Create UNTIL date string accounting for timezone offset
        const year = recurrenceEndDate.getFullYear()
        const month =
          recurrenceEndDate.getMonth() > 8
            ? Number(recurrenceEndDate.getMonth() + 1).toString()
            : '0' + Number(recurrenceEndDate.getMonth() + 1).toString()
        const date =
          recurrenceEndDate.getDate() > 9
            ? Number(recurrenceEndDate.getDate()).toString()
            : '0' + Number(recurrenceEndDate.getDate()).toString()
        const newUntilString = `UNTIL=${year}${month}${date}T${recurrenceEndDate.getHours()}${recurrenceEndDate.getMinutes()}${recurrenceEndDate.getSeconds()}Z`

        // Apply UNTIL date to recurrence string
        const untilIndex = obj.recurrence?.indexOf('UNTIL=')
        if(untilIndex > -1){
          obj.recurrence = `${obj.recurrence.substring(0,untilIndex)}${newUntilString}`
        }else{
          obj.recurrence += `;${newUntilString}`
        }
      }

      if (obj.recurrence === 'custom') {
        // If the recurrence is accidentally set to 'custom', remove all recurrence properties
        obj.recurrence = null
        obj.isCustomRecurrence = false
        obj.repeats = false
        obj.recurrenceEndDate = null
      }else if(obj.isCustomRecurrence && obj.recurrence !== ''){
        // Set the human-readable recurrence text
        try {
          const rule = rrulestr(obj.recurrence)
          recurrenceText = rule.toText()  
        } catch (error) {
          recurrenceText = 'Custom' 
        }
      }
    }

    const tacticPlatformIds = [] as ID[]
    if(obj.tacticPlatforms){
      obj.tacticPlatforms.forEach((element) => {
        tacticPlatformIds.push(ID.fromResponseObject(element, 'tactic_platforms'))
      })
    }

    const documentIds = [] as ID[]
    if (obj.documents) {
      obj.documents.forEach((element) => {
        documentIds.push(ID.fromResponseObject(element, 'documents'))
      })
    }

    // Catch invalid rrule formatting
    try {
      rrulestr(obj.recurrence)
    } catch (error) {
      obj.recurrence = null
    }

    // Set up relatedInitiatives array
    let relatedInitiatives = obj.relatedInitiatives ? obj.relatedInitiatives : []
    if(typeof obj.relatedInitiatives == 'string'){
      relatedInitiatives = JSON.parse(obj.relatedInitiatives)
    }
    if(Array.isArray(relatedInitiatives) && relatedInitiatives.length){
      relatedInitiatives = relatedInitiatives.map((mappingObj) => RelatedInitiativeMapping.fromResponseObject(obj.id, mappingObj))
    }else{
      relatedInitiatives = []
    }

    // TODO: Set isUnscheduled
    // const isUnscheduled = false
    const isUnscheduled = obj.isUnscheduled

    return new Tactic(
      ID.fromResponseObject(obj.id, 'tactics'),
      ID.fromResponseObject(obj.creator, 'users'),
      obj.title,
      new Date(startDateString),
      new Date(endDateString),
      ID.fromResponseObject(obj.tacticType, 'tactic_types'),
      obj.estimatedCost,
      obj.actualCost,
      obj.impressions,
      obj.ratingPoints,
      tagIds,
      typeValues,
      relatedInitiatives,
      obj.isFlagged,
      ID.fromResponseObject(obj.plan, 'plans'),
      obj.isAllDay,
      !!obj.recurrence,
      recurrenceEndDate,
      obj.isCustomRecurrence,
      recurrenceText,
      obj.recurrence && obj.recurrence !== '' ? obj.recurrence : null,
      tacticPlatformIds,
      obj.isNested ? obj.isNested : false,
      obj.isLead ? obj.isLead : false,
      documentIds,
      obj.isInitiative,
      obj.summary,
      isUnscheduled
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      creator: this.creatorId.intID == 0 ? null : this.creatorId.apiID,
      title: this.title,
      startDate: isValidDate(this.startDate)
        ? this.startDate
        : new Date(Date.now()),
      endDate: isValidDate(this.endDate) ? this.endDate : new Date(Date.now()),
      tacticType: this.tacticTypeId.intID == 0 ? null : this.tacticTypeId.apiID,
      estimatedCost: this.estimatedCost,
      actualCost: this.actualCost,
      impressions: this.impressions,
      ratingPoints: this.ratingPoints,
      tags: this.tags.map((tag) => {
        return tag.id.apiID
      }),
      typeValues: this.typeValues.map((value) => {
        return value.id.apiID
      }),
      relatedInitiatives: JSON.stringify(this.relatedInitiatives.filter((mappingObj) => mappingObj.initiativeId.intID !== 0).map((mappingObj) => mappingObj.forRequestObject())),
      isFlagged: this.isFlagged,
      plan: this.planId.intID == 0 ? null : this.planId.apiID,
      isAllDay: this.isAllDay,
      recurrence: this.getFormattedRecurranceString(),
      recurrenceEndDate: this.recurrenceEndDate,
      isCustomRecurrence: this.isCustomRecurrence,
      tacticPlatforms: this.tacticPlatforms?.map((platform)=>platform.id.apiID),
      documents: this.documents.map((document) => {
        return document.id.apiID
      }),
      isInitiative: this.isInitiative,
      summary: this.summary,
      isUnscheduled: this.isUnscheduled,
    }
  }
}
