<template>
  <div class="tactic-summary-container" :class="{'has-summary': tactic.summary && doesCurrentPlanSupportSummary}">
    <table class="tactic-details-container" ref="tacticDetailsContainer">
      <tr>
        <td>Topic:</td>
        <td>{{tactic.title}}</td>
      </tr>
      <template v-if="tactic.isInitiative">
        <tr>
          <td>Initiative:</td>
          <td>{{tacticTypeName}}</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td>Channel:</td>
          <td>{{tactic.channelName}}</td>
        </tr>
        <tr>
          <td>Tactic Type:</td>
          <td>{{tacticTypeName}}</td>
        </tr>
        <tr v-if="tactic.tacticPlatforms && tactic.tacticPlatforms.length">
          <td>Platform:</td>
          <td>{{tacticPlatformsString}}</td>
        </tr>
      </template>
      <template v-if="tactic.rrule != null">
        <tr>
          <td>Start Date:</td>
          <td>{{tacticStartDate}}</td>
        </tr>
        <tr v-if="tacticEndDate !== '' && tacticStartDate !== tacticEndDate">
          <td>End Date:</td>
          <td>{{tacticEndDate}}</td>
        </tr>
        <tr>
          <td>Repeats:</td>
          <td>{{repeatsDisplay}}</td>
        </tr>
        <tr>
          <td>End Repeat:</td>
          <td>{{tactic.recurrenceEndDate ? tactic.recurrenceEndDate.toLocaleDateString('en-US', dateOptions) : 'Never'}}</td>
        </tr>
      </template>
      <template v-else-if="tacticStartDate == tacticEndDate">
        <tr>
          <td>Date:</td>
          <td>{{tacticStartDate}}</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td>Start Date:</td>
          <td>{{tacticStartDate}}</td>
        </tr>
        <tr>
          <td>End Date:</td>
          <td>{{tacticEndDate}}</td>
        </tr>
      </template>
    </table>
    <template v-if="tactic.summary && doesCurrentPlanSupportSummary">
      <Skeleton width="100%" height="5rem" v-show="shouldShowSummaryLoadingSkeleton"></Skeleton>
      <table class="notes-container" ref="notesContainer" v-show="!shouldShowSummaryLoadingSkeleton">
        <tr>
          <td>Notes:</td>
        </tr>
        <tr>
          <td>
            <div class="notes-field-content-container ql-editor" v-html="tactic.summary"></div>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Tactic from '@/models/Tactic'
import {format, isSameDay} from 'date-fns'
import { AlertMessageSeverity } from '@/models/AlertMessage'
import Skeleton from 'primevue/skeleton';

Vue.component('Skeleton', Skeleton)

export default Vue.extend({
  name: 'TacticSummary',
  props: {
    tacticId: {
      type: Object,
    },
  },
  data: ()=>{
    return {
      tactic: {} as Tactic,
      shouldShowSummaryLoadingSkeleton: true as boolean,
    }
  },
  computed: {
    tacticTypeName: function(): string {
      return this.tactic.tacticTypeName
    },
    dateOptions: function(): object {
      if(this.tactic.isAllDay || this.tactic.isInitiative || this.tactic.tacticTypeName.toLowerCase() === this.$store.getters.monthlyFocusTypeName){
        return {year: 'numeric', month: 'long', day: 'numeric'}
      }
      return {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'}
    },
    tacticStartDate: function(): string {
      return this.tactic.startDate ? this.tactic.startDate.toLocaleDateString('en-US', this.dateOptions) : ''
    },
    tacticEndDate: function(): string {
      return this.tactic.endDate ? this.tactic.endDate.toLocaleDateString('en-US', this.dateOptions) : ''
    },
    doesCurrentPlanSupportSummary: function(): boolean {
      return this.$store.getters.currentPlan.isTacticSummaryFieldEnabled
    },
    tacticPlatformsString(): string {
      const platformNames = [] as string[]
      this.tactic.tacticPlatforms?.forEach((platform)=>{
        const thisPlatform = this.$store.getters.currentTacticPlatforms.find((planPlatform)=>planPlatform.id.intID == platform.id.intID)
        if(thisPlatform){
          platformNames.push(thisPlatform.name)
        }
      })
      return platformNames.join(',')
    },
    repeatOptions(): object[] {
      // TODO: Copied from src/views/TacticDetail.vue, consider combining in to src/AppStore.ts
      const startDate = new Date(this.tactic.startDate)
      const year = startDate.getFullYear()
      const month =
        startDate.getMonth() > 8
          ? startDate.getMonth() + 1
          : '0' + (startDate.getMonth() + 1)
      const date =
        startDate.getDate() > 9
          ? startDate.getDate()
          : '0' + startDate.getDate()
      const hours =
        startDate.getHours() > 9
          ? startDate.getHours()
          : '0' + startDate.getHours()
      const minutes =
        startDate.getMinutes() > 9
          ? startDate.getMinutes()
          : '0' + startDate.getMinutes()
      const seconds =
        startDate.getSeconds() > 9
          ? startDate.getSeconds()
          : '0' + startDate.getSeconds()

      const dtStart = this.tactic.startDate
        ? `DTSTART=${year}${month}${date}T${hours}${minutes}${seconds};`
        : ''
      return [
        {
          value: `${dtStart}FREQ=DAILY`,
          label: 'Daily',
        },
        {
          value: `${dtStart}FREQ=WEEKLY;WKST=SU;BYDAY=${format(
            startDate,
            'EEEEEE'
          ).toUpperCase()}`,
          label: `Weekly on ${format(startDate, 'EEEE')}`,
        },
        {
          value: `${dtStart}FREQ=MONTHLY;BYMONTHDAY=${format(
            startDate,
            'd'
          )}`,
          label: `Monthly on the ${format(startDate, 'do')}`,
        },
        {
          ...this.getMonthlyByDay(startDate, dtStart),
        },
        {
          value: `${dtStart}FREQ=YEARLY`,
          label: `Annually on ${format(startDate, 'MMM')} ${format(
            startDate,
            'd'
          )}`,
        },
        {
          value: 'custom',
          label: 'Custom',
        },
      ]
    },
    repeatsDisplay(): string {
      const endDatePosition = this.tactic.rrule?.indexOf(';UNTIL')
      const rruleWithoutEndDate = endDatePosition != undefined && endDatePosition > -1 ? this.tactic.rrule?.substring(0, endDatePosition) : this.tactic.rrule
      const thisValue = this.repeatOptions.find((option)=>option['value'] == rruleWithoutEndDate)
      return thisValue ? thisValue['label'] : 'Custom'
    }
  },
  created: function(){
    const tacticInStore = this.$store.getters.currentPlan.tactics.find((tactic)=>tactic.id.intID === this.tacticId?.intID)
    this.tactic = tacticInStore ? tacticInStore : new Tactic()

    // Load tactic summary if not already loaded
    if(!this.tactic.summary || this.tactic.summary === '<p></p>'){
      this.$store.getters.services.tactics.getTacticSummary(this.tactic.id.intID).then(
        (response)=>{
          this.tactic.summary = response
          this.shouldShowSummaryLoadingSkeleton = false
        },
        (error)=>{
          this.shouldShowSummaryLoadingSkeleton = false
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error fetching summary.',
            life: 3000,
          })
        }
      )
    }else{
      this.shouldShowSummaryLoadingSkeleton = false
    }
  },
  methods: {
    getMonthlyByDay(
      startDate: Date,
      dtStart: string,
      onlyReturnMonthlyRule = false
    ) {
      // TODO: Copied from src/views/TacticDetail.vue, consider combining in to src/AppStore.ts
      const d = new Date(startDate.getTime())
      const dayOfWeek = d.getDay()
      const month = d.getMonth()
      const datesWithSameDayOfWeek = [] as Date[]

      // Get the first instance of the day of week in the month
      d.setDate(1)
      while (d.getDay() !== dayOfWeek) {
        d.setDate(d.getDate() + 1)
      }

      // get all instances of day of week
      while (d.getMonth() === month) {
        datesWithSameDayOfWeek.push(new Date(d))
        d.setDate(d.getDate() + 7)
      }

      // find our start date's index of those
      let indexOfStartDate = 0
      datesWithSameDayOfWeek.forEach((date, index) => {
        if (isSameDay(startDate, date)) {
          indexOfStartDate = index
        }
      })

      const isFifthInstanceOfWeekDay = indexOfStartDate > 4

      if (onlyReturnMonthlyRule) {
        return {
          value: isFifthInstanceOfWeekDay
            ? `BYSETPOS=-1;BYDAY=${format(startDate, 'EEEEEE').toUpperCase()}`
            : `BYSETPOS=${indexOfStartDate + 1};BYDAY=${format(
                startDate,
                'EEEEEE'
              ).toUpperCase()}`,
          label: isFifthInstanceOfWeekDay
            ? `Monthly on last ${format(startDate, 'eeee')} of the month`
            : `The ${indexOfStartDate + 1}${
                ['st', 'nd', 'rd'][
                  ((((indexOfStartDate + 1 + 90) % 100) - 10) % 10) - 1
                ] || 'th'
              } ${format(startDate, 'eeee')} of the month`,
        }
      }
      return {
        value: isFifthInstanceOfWeekDay
          ? `${dtStart}FREQ=MONTHLY;BYSETPOS=-1;BYDAY=${format(
              startDate,
              'EEEEEE'
            ).toUpperCase()}`
          : `${dtStart}FREQ=MONTHLY;BYSETPOS=${
              indexOfStartDate + 1
            };BYDAY=${format(startDate, 'EEEEEE').toUpperCase()}`,
        label: isFifthInstanceOfWeekDay
          ? `Monthly on last ${format(startDate, 'eeee')} of the month`
          : `Monthly on the ${indexOfStartDate + 1}${
              ['st', 'nd', 'rd'][
                ((((indexOfStartDate + 1 + 90) % 100) - 10) % 10) - 1
              ] || 'th'
            } ${format(startDate, 'eeee')} of the month`,
      }
    },
  }
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.tactic-summary-overlay {
  pointer-events: none;

  // Panel above tactic, offset so caret aligns with top of tactic box
  &.p-overlaypanel-flipped {
    transform: translateY(-10px);
  }
}
.tactic-summary-container {
  min-width: 20rem;
  max-width: 30rem;
  height: auto;

  .tactic-details-container {
    tr {
      td:first-of-type {
        min-width: 8rem;
        padding-right: 1rem;
      }
    }
  }
  .notes-container {
    width: 100%;
  }
  .notes-field-content-container {
    width: 100%;
    max-width: 30rem;
    max-height: 400px;
    overflow: hidden;
  }
}
</style>