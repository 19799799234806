import CustomView from './CustomView'
import MediaAsset from './MediaAsset'
import ID from './ID'
import Role from './Role'
import Channel from './Channel'
import Account from './Account'

export enum UserMostRecentView {
  'dashboard' = 'dashboard',
  'calendar' = 'calendar',
  'budget' = 'budget',
  'view' = 'view',
}

export enum UserStatus {
  'pending' = 'pending',
  'accepted' = 'accepted',
}

export default class User {
  public editingValues: any
  public resendInvite = false

  constructor(
    public id: ID = new ID(),
    public emailAddress: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public profileImage: MediaAsset = new MediaAsset(),
    public customViews: CustomView[] = [],
    public mostRecentView: UserMostRecentView = UserMostRecentView.dashboard,
    public mostRecentPlanId: ID = new ID(),
    public status: string = '',
    public rolesObserved = [],
    public password: string = '',
    public accounts: Account[] = [],
    
    public channels: [] = [], // Populated on front end
    public oldEmailAddress: string = '', // Populated on front end
  ) {
    this.editingValues = {
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
    }
  }

  public static SUPER_USER_IDS = [
    3, // Patty
    4, // Voth
    2, // Shane
    300, // Saverda
  ] as number[]

  public get key() {
    return 'user-' + this.id.intID.toString()
  }

  public get initials(): string {
    return this.firstName.length && this.lastName.length ? this.firstName[0] + this.lastName[0] : 'AA'
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  public get isSuperUser(): boolean {
    return User.SUPER_USER_IDS.includes(this.id.intID)
  }

  public isValidBySearchText(text: string) {
    if (
      this.firstName.toLowerCase().includes(text) ||
      this.lastName.toLowerCase().includes(text)
    ) {
      return true
    }
    return false
  }

  public transformForEditing(): User {
    this.firstName = this.editingValues.firstName
    this.lastName = this.editingValues.lastName
    this.emailAddress = this.editingValues.emailAddress
    return this
  }

  public updateOldEmailAddress(){
    this.oldEmailAddress = (' ' + this.emailAddress).slice(1)
    this.editingValues.emailAddress = this.emailAddress
  }

  public get accountIds(): ID[] {
    return this.accounts.map((account)=>account.id)
  }

  public static fromResponseObject(obj): User {
    if (!obj) return new User()

    const customViews = [] as CustomView[]
    obj.customViews && obj.customViews.forEach((element) => {
        customViews.push(CustomView.fromResponseObject(element))
      })

    const accounts = [] as Account[]
    if (obj.accounts && obj.accounts.length) {
      obj.accounts.forEach((element) => {
        accounts.push(Account.fromResponseObject(element))
      })
    }

    return new User(
      ID.fromResponseObject(obj.id, 'users'),
      obj.emailAddress,
      obj.firstName,
      obj.lastName,
      MediaAsset.fromResponseObject(obj.profileImage),
      customViews,
      obj.mostRecentView,
      ID.fromResponseObject(obj.mostRecentPlanId, 'plans'),
      obj.status,
      obj.rolesObserved,
      obj.password,
      accounts,
      [],
      obj.emailAddress
    )
  }

  public forRequestObject() {
    const returnObj = {
      id: this.id.intID == 0 ? null : this.id.apiID,
      emailAddress: this.emailAddress,
      firstName: this.firstName,
      lastName: this.lastName,
      profileImage: this.profileImage.id?.intID !== 0 ? this.profileImage.id.apiID : null,
      customViews: this.customViews.length ? this.customViews.map((customView) => customView.id.apiID) : [],
      mostRecentView: this.mostRecentView
        ? this.mostRecentView
        : UserMostRecentView.dashboard,
      mostRecentPlanId: this.mostRecentPlanId.intID,
      status: this.status,
      oldEmail: this.oldEmailAddress,
    }
    if (this.password != '') {
      returnObj['password'] = this.password
    }
    return returnObj
  }
}
