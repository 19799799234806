<template>
  <div class="logged-out-container p-d-flex p-flex-column p-jc-center p-ai-center">
    <h3>Logging In...</h3>
  </div>
</template>

<script lang="ts">
import { AlertMessageSeverity } from '@/models/AlertMessage'
import Vue from 'vue'

export default Vue.extend({
  name: 'LogInBypass',
  data: () => {
    return {}
  },
  mounted() {
    // Test for presence of refreshToken cookie and redirect to sign in view if now found

    // OLD: Get refreshToken from cookie
    // const refreshTokenTest = new RegExp(/^(refreshToken=)\S/)
    // !refreshTokenTest.test(document.cookie)

    // Get refreshToken from URL param
    if (!this.$route.params.refreshToken) {
      Vue.prototype.$toast.add({
        severity: AlertMessageSeverity.error,
        summary: 'Refresh token not available.',
      })
      this.$router.push('/sign-in')
      return
    }

    // Log in with refreshToken cookie
    Vue['cookie'].set('refreshToken', this.$route.params.refreshToken, {expires: '1D', sameSite: 'None', secure: true, domain: 'annumapp.com'})

    this.$store.dispatch('refreshAccessToken').then(
      ()=>{
        this.$router.push('/')
      },
      (error)=>{
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.error,
          summary: error,
        })
        this.$router.push('/sign-in')
      }
    )
  },
})
</script>

<style lang="scss">
</style>