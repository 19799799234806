<template>
  <div class="view-wrapper calendar-view">
    <ViewHeader :title="$store.getters.currentPlan.name">
      <template #actions>
        <Button
          label="Add Tactic"
          icon="pi pi-plus-circle"
          class="p-mr-2"
          @click="onAddTactic"
        />
        <Button
          label="Filters"
          icon="pi pi-sliders-h"
          class="p-button-outlined"
          @click="showTacticFiltersView = true"
          :badge="$store.getters.activeFiltersCount"
          badgeClass="p-badge-info"
        />
      </template>
      <template>
        <div class="p-d-flex">
          <div class="p-d-flex">
            <div>
              <DateRangePicker
                :isRangeView="isFlowView"
                :isMonthView="$route.name == 'CalendarMonth'"
                :isDayView="$route.name == 'CalendarDay'"
              />
            </div>
            <div class="p-field p-ml-4" v-if="isFlowView">
              <label>Week Starts On:</label>
              <div class="p-d-flex p-mt-3">
                <div class="p-d-flex p-mr-3">
                  <RadioButton
                    id="weekStart_sunday"
                    name="weekStart"
                    value="sunday"
                    v-model="plan.weekStartDay"
                    :disabled="!canUserEditPlanProperties"
                    class="p-mr-2"
                    @change="handleSavePlan"
                  />
                  <label for="weekStart_sunday">Sunday</label>
                </div>
                <div class="p-d-flex">
                  <RadioButton
                    id="weekStart_monday"
                    name="weekStart"
                    value="monday"
                    v-model="plan.weekStartDay"
                    :disabled="!canUserEditPlanProperties"
                    class="p-mr-2"
                    @change="handleSavePlan"
                  />
                  <label for="weekStart_monday">Monday</label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isFlowView && (isLeadPlan || isNestedPlan) && $store.getters.currentPlan.isBudgetEnabled && $store.getters.currentUserRole.canViewBudget" class="budget-display-instructions p-ml-4">
            <template v-if="isLeadPlan">
              <p v-if="shouldShowBudgetData">Budget numbers relate only to this Lead Plan.</p>
              <p v-else class="error">To see budget numbers for the Lead Plan you must toggle off all of the Nested Plans.</p>
            </template>
            <template v-else>
              <p v-if="shouldShowBudgetData">Budget numbers relate only to this Nested Plan.</p>
              <p v-else class="error">To see budget numbers for this Nested Plan you must toggle off the Lead Plan.</p>
            </template>
          </div>
        </div>
      </template>
    </ViewHeader>
    <ViewMain>
      <transition name="view-fade-transition">
        <router-view name="top" class="calendar-top-view" />
      </transition>
      <transition name="view-fade-transition">
        <router-view name="bottom" class="calendar-bottom-view" />
      </transition>

      <TacticFilters
        :showHide="showTacticFiltersView"
        @shouldHide="showTacticFiltersView = false"
      />
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Toolbar from 'primevue/toolbar'
import Button from 'primevue/button'
import Panel from 'primevue/panel'
import TabMenu from 'primevue/tabmenu'
import TacticFilters from '@/components/TacticFilters.vue'
import Calendar from 'primevue/calendar'
import RadioButton from 'primevue/radiobutton'
import DateRangePicker from '@/components/DateRangePicker.vue'
import Role from '@/models/Role'
import Plan from '@/models/Plan'
import { AlertMessageSeverity } from '@/models/AlertMessage'

Vue.component('Toolbar', Toolbar)
Vue.component('Button', Button)
Vue.component('Panel', Panel)
Vue.component('TabMenu', TabMenu)
Vue.component('Calendar', Calendar)
Vue.component('RadioButton', RadioButton)

export default Vue.extend({
  name: 'CalendarView',
  components: {
    ViewHeader,
    ViewMain,
    DateRangePicker,
    TacticFilters,
  },
  data: function () {
    return {
      showTacticFiltersView: false,
    }
  },
  computed: {
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    isNestedPlan(): boolean {
      return this.$store.getters.currentPlan.parentId.intID !== 0 ? true : false
    },
    canUserAddTactics(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_CONTRIBUTOR
        ? true
        : false
    },
    canUserEditPlanProperties(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    isFlowView(): boolean {
      return this.$route.name === 'CalendarFlow'
    },
    shouldShowBudgetData(): boolean {
      return (
        this.$store.getters.currentPlan.isBudgetEnabled &&
        this.$store.getters.currentUserRole.canViewBudget &&
        (
          (!this.isLeadPlan && !this.isNestedPlan) ||
          (this.isNestedPlan && !this.$store.getters.isLeadPlanVisible) ||
          (this.isLeadPlan && !this.$store.getters.visibleNestedPlansIntIds?.length)
        )
      )
    },
  },
  methods: {
    onAddTactic: function () {
      if(this.canUserAddTactics){
        // Calculate date and time for new tactic
        let day = this.$store.getters.currentDateRange[0]?.getDate();
        if(this.$route.path.includes('month')){
          // set date to first of month if in month view
          day = 1
        }
        const dateString = `${this.$store.getters.currentDateRange[0]?.getFullYear()}-${(this.$store.getters.currentDateRange[0]?.getMonth() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T08:00:00`;

        // Open TacticDetail with date string
        this.$router.push({
          path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$store.getters.currentPlan.id.intID}/tactic/0?datetime=${dateString}`
        })
      }else{
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Viewers do not have the ability to add to or edit the Plan.',
          life: 3000,
        })
      }
    },
    handleSavePlan() {
      this.plan.accountId = this.$store.getters.currentAccount.id
      this.$store.getters.services.plans
        .update(this.plan)
        .then((plan) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Week start day updated.',
            life: 3000,
          })
          this.$store.dispatch('updateCurrentPlan', plan)
        })
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.calendar-bottom-view {
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }
}
</style>
