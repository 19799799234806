import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import AccountDetail from '@/views/AccountDetail.vue'
import Budget from '@/views/Budget.vue'
import Calendar from '@/views/Calendar.vue'
import CalendarFlow from '@/views/CalendarFlow.vue'
import CalendarGrid from '@/views/CalendarGrid.vue'
import CalendarOverstory from '@/components/CalendarOverstory.vue'
import PlanList from '@/views/PlanList.vue'
import Search from '@/views/Search.vue'
import UserDetail from '@/views/UserDetail.vue'
import TacticDetail from '@/views/TacticDetail.vue'
import Table from '@/views/Table.vue'
import LogIn from '@/views/LogIn.vue'
import LogInBypass from '@/views/LogInBypass.vue'
import LogInError from '@/views/LogInError.vue'
import PasswordForgot from '@/views/PasswordForgot.vue'
import PasswordChange from '@/views/PasswordChange.vue'
import PasswordSet from '@/views/PasswordSet.vue'
// import TacticImport from '@/views/TacticImport.vue'
import SetupIntro from '@/views/SetupIntro.vue'
import SetupRoot from '@/views/SetupRoot.vue'
// import SetupDefaultChannels from '@/core/views/SetupDefaultChannels.archived.vue'
// import SetupCustomChannels from '@/core/views/SetupCustomChannels.archived.vue'
// import SetupSubscriptions from '@/core/views/SetupSubscriptions.archived.vue'
// import SetupBudget from '@/core/views/SetupBudget.archived.vue'
// import SetupDocuments from '@/core/views/SetupDocuments.archived.vue'
// import SetupInitiatives from '@/core/views/SetupInitiatives.archived.vue'
// import SetupTactics from '@/core/views/SetupTactics.archived.vue'
// import SetupTags from '@/core/views/SetupTags.archived.vue'
// import SetupUsers from '@/core/views/SetupUsers.archived.vue'
import PlanSettingsBudget from '@/views/PlanSettingsBudget.vue'
import PlanSettingsTags from '@/views/PlanSettingsTags.vue'
import PlanSettingsInitiatives from '@/views/PlanSettingsInitiatives.vue'
import PlanSettingsChannels from '@/views/PlanSettingsChannels.vue'
import PlanSetingsSubscriptions from '@/views/PlanSettingsSubscriptions.vue'
import PlanSettingsUsers from '@/views/PlanSettingsUsers.vue'
import PlanSettingsDocuments from '@/views/PlanSettingsDocuments.vue'
import MaintenanceMode from '@/views/MaintenanceMode.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/account',
    name: 'AccountDetail',
    components: {
      main: AccountDetail,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/user/:userId',
    name: 'UserDetail',
    components: {
      main: UserDetail,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },

  {
    path: '/',
    alias: '/plans',
    name: 'PlanList',
    components: {
      main: PlanList,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/budget',
    name: 'Budget',
    components: {
      main: Budget,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
    //TODO: add date range param
  },
  {
    path: '/account/:accountId/plan/:planId/dashboard',
    redirect: '/account/:accountId/plan/:planId/calendar',
  },
  {
    path: '/account/:accountId/plan/:planId/calendar',
    components: {
      main: Calendar,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
    children: [
      {
        path: '',
        name: 'CalendarDefault',
        components: {
          top: CalendarOverstory,
          bottom: CalendarGrid,
        },
        meta: {
          title: process.env.VUE_APP_TITLE,
        },
      },
      {
        path: 'day',
        name: 'CalendarDay',
        components: {
          top: CalendarOverstory,
          bottom: CalendarGrid,
        },
        meta: {
          title: process.env.VUE_APP_TITLE,
        },
      },
      {
        path: 'flow',
        name: 'CalendarFlow',
        components: {
          top: CalendarFlow,
        },
        meta: {
          title: process.env.VUE_APP_TITLE,
        },
      },
      {
        path: 'month',
        name: 'CalendarMonth',
        components: {
          top: CalendarOverstory,
          bottom: CalendarGrid,
        },
        meta: {
          title: process.env.VUE_APP_TITLE,
        },
      },
    ],
  },
  {
    path: '/account/:accountId/plan/:planId/search',
    name: 'Search',
    components: {
      main: Search,
    },
    props: {
      main: (route) => ({searchQuery: route.query.q}),
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/tactic/:tacticId',
    name: 'TacticDetail',
    components: {
      main: TacticDetail,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/initiative/:tacticId',
    name: 'InitiativeDetail',
    components: {
      main: TacticDetail,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/initiative/:tacticId',
    name: 'PlanInitiativeDetail',
    components: {
      main: TacticDetail,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/initiative/:tacticId',
    name: 'AccountInitiativeDetail',
    components: {
      main: TacticDetail,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/table',
    name: 'Table',
    components: {
      main: Table,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },

  // Plan settings
  {
    path: '/account/:accountId/plan/:planId/settings/channels/',
    name: 'PlanSettingsChannels',
    components: {
      main: PlanSettingsChannels,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/calendars-of-interest/',
    name: 'PlanSetingsSubscriptions',
    components: {
      main: PlanSetingsSubscriptions,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/strategic-priorities/',
    name: 'PlanSettingsTags',
    components: {
      main: PlanSettingsTags,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/initiatives/',
    name: 'PlanSettingsInitiatives',
    components: {
      main: PlanSettingsInitiatives,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/budget/',
    name: 'PlanSettingsBudget',
    components: {
      main: PlanSettingsBudget,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/documents/',
    name: 'PlanSettingsDocuments',
    components: {
      main: PlanSettingsDocuments,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },
  {
    path: '/account/:accountId/plan/:planId/settings/team/',
    name: 'PlanSettingsUsers',
    components: {
      main: PlanSettingsUsers,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
    },
  },

  // Onboarding flow
  {
    path: '/setup/:setupMode/:setupAccountId/:setupPlanId',
    redirect: '/setup/:setupMode/:setupAccountId/:setupPlanId/intro',
    name: 'SetupRoot',
    components: {
      main: SetupRoot,
    },
    children: [
      {
        path: 'intro',
        component: SetupIntro,
      },
      // {
      //   path: 'default-channels',
      //   component: SetupDefaultChannels,
      // },
      // {
      //   path: 'custom-channels',
      //   component: SetupCustomChannels,
      // },
      // {
      //   path: 'calendars',
      //   component: SetupSubscriptions,
      // },
      // {
      //   path: 'budget',
      //   component: SetupBudget,
      // },
      // {
      //   path: 'tags',
      //   component: SetupTags,
      // },
      // {
      //   path: 'initiatives',
      //   component: SetupInitiatives,
      // },
      // {
      //   path: 'documents',
      //   component: SetupDocuments,
      // },
      // {
      //   path: 'team',
      //   component: SetupUsers,
      // },
      // {
      //   path: 'tactics',
      //   component: SetupTactics,
      // },
    ],
  },

  // Logged out views
  {
    path: '/sign-in',
    name: 'SignIn',
    components: {
      loggedOut: LogIn,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },
  {
    path: '/sign-in/bypass/:refreshToken',
    name: 'LogInBypass',
    components: {
      loggedOut: LogInBypass,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },
  {
    path: '/sign-in/error/',
    alias: '/sign-in/saml-error/',
    name: 'LogInError',
    components: {
      loggedOut: LogInError,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    components: {
      loggedOut: PasswordForgot,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },
  {
    path: '/change-password',
    name: 'PasswordChange',
    components: {
      loggedOut: PasswordChange,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },
  {
    path: '/set-password/:token',
    name: 'PasswordSet',
    components: {
      loggedOut: PasswordSet,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },
  {
    path: '/maintenance-mode',
    name: 'MaintenanceMode',
    components: {
      loggedOut: MaintenanceMode,
    },
    meta: {
      title: process.env.VUE_APP_TITLE,
      isPublic: true,
    },
  },

  // Import/Export
  // {
  //   path: '/account/:accountId/plan/:planId/import',
  //   name: 'TacticImport',
  //   components: {
  //     main: TacticImport,
  //   },
  //   meta: {
  //     title: process.env.VUE_APP_TITLE,
  //   },
  // },

  // {
  //   path: '/account/:accountId/plan/:planId/import/:importId/map',
  //   name: 'TacticImportMap',
  //   components: {
  //     main: TacticImport,
  //   },
  //   meta: {
  //     title: process.env.VUE_APP_TITLE,
  //   },
  // },

  // {
  //   path: '/account/:accountId/plan/:planId/import/:importId/confirm',
  //   name: 'TacticImportConfirm',
  //   components: {
  //     main: TacticImport,
  //   },
  //   meta: {
  //     title: process.env.VUE_APP_TITLE,
  //   },
  // },

  //TODO: use this approach to lazy-load views that a user may not have permissions to access
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
