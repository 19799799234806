<template>
  <div class="overstory-container">
    <div class="flow-dates-row p-d-flex">
      <div
        v-for="(month) in currentFlowDisplayMonths"
        :key="month.index"
        class="cell"
        :style="{width: (month.numDays * dayWidth).toString() + '%'}"
      >
        <div class="dates p-d-flex">
          <div
            v-for="week in month.weeks"
            :key="week.index"
            class="date"
            :class="{'starts-mid-week': week.startsMidWeek}"
            :style="{
              width:
                Number((week.numDays / month.numDays) * 100).toString() + '%',
            }"
          >
            {{ !month.monthStartsOnWeekStartDay && week.startsMidWeek ? '' : week.firstDay }}
          </div>
        </div>
      </div>
    </div>
    <Accordion
      :multiple="true"
      :activeIndex="accordionActiveIndicies"
      class="widgets-accordion flow-accordion"
      @tab-open="onAccordionTabOpen"
      @tab-close="onAccordionTabClose"
    >
      <AccordionTab
        header="Calendars of Interest"
        :disabled="!filteredSubscriptionEvents.length"
      >
        <template #default v-if="filteredSubscriptionEvents.length">
          <div class="flow-events-row p-d-flex">
            <div class="events-container">
              <CalendarFlowRow
                :tactics="filteredSubscriptionEvents"
                :dayWidth="dayWidth"
                :millisecondsPerDay="millisecondsPerDay"
                :dateRange="dateRange"
                :isInsideOverstory="true"
                :isSubscription="true"
              />
            </div>
          </div>
        </template>
      </AccordionTab>
      <AccordionTab
        :disabled="!filteredInitiatives.length"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center" style="width: 100%;">
            <div class="p-accordion-header-text">Initiatives</div>
          </div>
        </template>
        <template #default v-if="filteredInitiatives.length">
          <div class="flow-events-row p-d-flex">
            <div class="events-container">
              <CalendarFlowRow
                :tactics="filteredInitiatives"
                :dayWidth="dayWidth"
                :millisecondsPerDay="millisecondsPerDay"
                :dateRange="dateRange"
                :isInsideOverstory="true"
                @showTactic="showInitiative"
                @showContextMenu="showContextMenu"
                @showTacticSummaryOverlay="showTacticSummaryOverlay"
                @hideTacticSummaryOverlay="hideTacticSummaryOverlay"
              />
            </div>
          </div>
        </template>
      </AccordionTab>
      <AccordionTab
        header="Monthly Channel Focus"
        :disabled="!monthlyFocusTactics.length"
      >
        <template #default v-if="monthlyFocusTactics.length">
          <div class="flow-events-row p-d-flex">
            <div class="events-container">
              <CalendarFlowRow
                :tactics="monthlyFocusTactics"
                :dayWidth="dayWidth"
                :millisecondsPerDay="millisecondsPerDay"
                :dateRange="dateRange"
                :isInsideOverstory="true"
                @showTactic="showTactic"
                @showContextMenu="showContextMenu"
                @showTacticSummaryOverlay="showTacticSummaryOverlay"
                @hideTacticSummaryOverlay="hideTacticSummaryOverlay"
              />
            </div>
          </div>
        </template>
      </AccordionTab>
      <AccordionTab
        header="Long-Running"
        :disabled="!filteredPaidChannelTactics.length"
      >
        <template #default v-if="filteredPaidChannelTactics.length">
          <div class="flow-events-row p-d-flex">
            <div class="events-container">
              <CalendarFlowRow
                :tactics="filteredPaidChannelTactics"
                :dayWidth="dayWidth"
                :millisecondsPerDay="millisecondsPerDay"
                :dateRange="dateRange"
                :isInsideOverstory="true"
                @showTactic="showTactic"
                @showContextMenu="showContextMenu"
                @showTacticSummaryOverlay="showTacticSummaryOverlay"
                @hideTacticSummaryOverlay="hideTacticSummaryOverlay"
              />
            </div>
          </div>
        </template>
      </AccordionTab>
    </Accordion>

    <ContextMenu ref="menu" :model="contextMenuItems" appendTo="body" />

    <OverlayPanel ref="tacticSummaryOverlay" class="tactic-summary-overlay" appendTo="body">
      <TacticSummary :tacticId="currentSummaryTacticId" />
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Channel from '@/models/Channel'
import Tactic from '@/models/Tactic'
import ID from '@/models/ID'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Subscription from '@/models/Subscription'
import OverlayPanel from 'primevue/overlaypanel'
import TacticSummary from '@/views/TacticSummary.vue'
import CalendarFlowRow from '@/components/CalendarFlowRow.vue'
import FlowDisplayMonth from '@/models/FlowDisplayMonth'
import ContextMenuItem from '@/models/ContextMenuItem'
import ContextMenu from 'primevue/contextmenu'
import RelatedInitiativeMapping from '@/models/RelatedInitiativeMapping'

Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)
Vue.component('ContextMenu', ContextMenu)


export default Vue.extend({
  name: 'CalendarOverstory',
  components: {
    CalendarFlowRow,
    TacticSummary,
  },
  data: () => {
    return {
      window: window,
      currentSummaryTacticId: {} as ID,
      rightClickedTacticId: {} as ID,
      rightClickedTacticIsInitiative: false as boolean,
      millisecondsPerDay: (1000 * 60 * 60 * 24) as number,
      dayWidthTargetPx: 30 as number,
      currentSummaryTimeout: 0,
      isDisplayingSummaryTactic: false as boolean,
      updateItterator: 0 as number,
    }
  },
  computed: {
    dateRange(): Date[] {
      return this.$store.getters.currentDateRange
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    dayWidth(): number {
      if (!this.dateRange[1] || this.dateRange[0] == this.dateRange[1]) {
        return 100
      }
      const utc1 = Date.UTC(
        this.dateRange[0].getFullYear(),
        this.dateRange[0].getMonth(),
        this.dateRange[0].getDate()
      )
      const utc2 = Date.UTC(
        this.dateRange[1].getFullYear(),
        this.dateRange[1].getMonth(),
        this.dateRange[1].getDate()
      )
      // Calculate milliseconds between start and end dates and add one day so last day of range is displayed
      return 100 / ((utc2 - utc1 + this.millisecondsPerDay) / this.millisecondsPerDay)
    },
    monthlyFocusTactics(): Tactic[] {
      const returnArray = [] as Tactic[]
      this.$store.getters.selectedChannels.forEach((channel) => {
        if (channel.useMonthlyFocusType == true) {
          channel.tacticTypes.forEach((type) => {
            if (
              this.$store.getters.selectedTacticTypes.map((type)=>type.id.intID).includes(type.id.intID) &&
              type.name.toLowerCase() == this.$store.getters.monthlyFocusTypeName
            ) {
              type.tactics.forEach((tactic) => {
                if (
                  !this.isTacticInFilteredOutPlan(tactic) &&
                  !this.isTacticRelatedToDeselectedTag(tactic) &&
                  !this.isTacticRelatedToDeselectedInitiative(tactic) &&
                  this.isTacticExactMonth(tactic)
                  ) {
                  returnArray.push(tactic)
                }
              })
            }
          })
        }
      })
      return returnArray
    },
    filteredSubscriptionEvents(): Tactic[] {
      const returnArray = [] as Tactic[]
      this.$store.getters.selectedSubscriptions.forEach((subscription) => {
        const subscriptionCopy = {...subscription}

        // Add recurrence clones
        this.$store.dispatch('addRecurringEventClones', subscriptionCopy)

        subscriptionCopy.events.forEach((tactic) => {
          if (
            this.isTacticTouchingMonth(tactic) &&
            this.isTacticLongerThanThreshold(tactic, 21) &&
            !this.isEventDuplicate(tactic, subscriptionCopy)
          ) {
            returnArray.push(tactic)
          }
        })
      })
      return returnArray
    },
    filteredInitiatives(): Tactic[] {
      const returnArray = [] as Tactic[]
      this.$store.getters.selectedInitiatives.forEach((tactic) => {
        if (
          !this.isTacticInFilteredOutPlan(tactic) &&
          this.isTacticTouchingMonth(tactic)
          ) {
          returnArray.push(tactic)
        }
      })
      returnArray.sort((a: Tactic, b: Tactic) => {
        if (a.isLead && !b.isLead) {
          return -1
        }
        if (b.isLead && !a.isLead) {
          return 1
        }
        return 0
      })
      return returnArray
    },
    filteredPaidChannelTactics(): Tactic[] {
      const returnArray = [] as Tactic[]
      this.$store.getters.selectedTacticTypes.forEach((type) => {
        type.tactics.forEach((tactic) => {
          if (
            !this.isTacticInFilteredOutPlan(tactic) &&
            !this.isTacticRelatedToDeselectedTag(tactic) &&
            !this.isTacticRelatedToDeselectedInitiative(tactic) &&
            this.isTacticLongerThanThreshold(tactic, 21) &&
            this.isTacticInPaidChannel(tactic) &&
            this.isTacticTouchingMonth(tactic)
          ) {
            returnArray.push(tactic)
          }
        })
      })
      return returnArray
    },
    accordionActiveIndicies(): number[] {
      const returnArray = [] as number[]
      this.$store.getters.currentDateRange //Hack to force computed property update when date range changes
      if (
        window.localStorage.getItem('mh-subscriptions') != 'false' &&
        this.filteredSubscriptionEvents.length
      ) {
        returnArray.push(0)
      }
      if (
        window.localStorage.getItem('mh-initiatives') != 'false' &&
        this.filteredInitiatives.length
      ) {
        returnArray.push(1)
      }
      if (
        window.localStorage.getItem('mh-monthlyfocus') != 'false' &&
        this.monthlyFocusTactics.length
      ) {
        returnArray.push(2)
      }
      if (
        window.localStorage.getItem('mh-inmarketmedia') != 'false' &&
        this.filteredPaidChannelTactics.length
      ) {
        returnArray.push(3)
      }
      return returnArray
    },
    currentFlowDisplayMonths(): FlowDisplayMonth[] {
      const returnArray = [] as FlowDisplayMonth[]
      if (!this.dateRange[0] || !this.dateRange[1]) return returnArray

      const tempDate = new Date(this.dateRange[0].toString())
      let numMonths =
        (this.dateRange[1].getFullYear() - this.dateRange[0].getFullYear()) * 12
      numMonths -= this.dateRange[0].getMonth()
      numMonths += this.dateRange[1].getMonth()

      for (let i = 0; i <= numMonths; i += 1) {
        if (i > 0) {
          tempDate.setMonth(tempDate.getMonth() + 1)
        }
        returnArray.push(
          new FlowDisplayMonth(
            i,
            tempDate.toLocaleString('default', {
              month: 'short',
            }),
            i == 0 ? this.dateRange[0].getDate() : 1,
            i == numMonths
              ? this.dateRange[1].getDate()
              : new Date(
                  tempDate.getFullYear(),
                  tempDate.getMonth() + 1,
                  0
                ).getDate(),
            tempDate.getMonth(),
            tempDate.getFullYear(),
            this.$store.getters.currentPlan.weekStartDay
          )
        )
      }
      return returnArray
    },
    contextMenuItems(): ContextMenuItem[] {
      return [
        {
          label: `Copy ${this.rightClickedTacticIsInitiative ? 'Initiative' : 'Tactic'}`,
          icon: 'pi pi-fw pi-copy',
          command: this.openTacticDetailWithCopyId,
        },
      ]
    },
  },
  mounted() {
    // Force update with newest tactics
    this.$store.dispatch('refreshCurrentPlan')
  },
  methods: {
    // TODO: add to logic for long-running
    isTacticInFilteredOutPlan(tactic: Tactic): boolean {
      if (tactic.isLead && !this.$store.getters.isLeadPlanVisible) {
        return true
      }
      if (
        tactic.isNested &&
        !this.$store.getters.visibleNestedPlansIntIds?.find(
          (planIntId) => planIntId === tactic.planId.intID
        )
      ) {
        return true
      }
      return false
    },
    isTacticTouchingMonth(tactic: Tactic): boolean {
      if (
        tactic.endDate <= this.dateRange[0] ||
        tactic.startDate >= this.dateRange[1]
      ) {
        return false
      }
      return true
    },
    isTacticMultiMonth(tactic: Tactic): boolean {
      return (
        (this.dateRange[0] > tactic.startDate &&
          this.dateRange[0] < tactic.endDate) ||
        (this.dateRange[1] > tactic.startDate &&
          this.dateRange[1] < tactic.endDate)
      )
    },
    isTacticEntireMonth(tactic: Tactic): boolean {
      return (
        this.dateRange[0] <= tactic.startDate &&
        this.dateRange[1] >= tactic.endDate
      )
    },
    isTacticExactMonth(tactic: Tactic): boolean {
      return (
        this.dateRange[0].getFullYear() == tactic.startDate.getFullYear() &&
        this.dateRange[0].getMonth() == tactic.startDate.getMonth() &&
        this.dateRange[1].getFullYear() == tactic.endDate.getFullYear() &&
        this.dateRange[1].getMonth() == tactic.endDate.getMonth() 
      )
    },
    isTacticLongerThanThreshold(
      tactic: Tactic,
      thresholdInDays: number
    ): boolean {
      return (
        Math.ceil(
          (Date.UTC(
            tactic.endDate.getFullYear(),
            tactic.endDate.getMonth(),
            tactic.endDate.getDate()
          ) -
            Date.UTC(
              tactic.startDate.getFullYear(),
              tactic.startDate.getMonth(),
              tactic.startDate.getDate()
            )) /
            (1000 * 60 * 60 * 24)
        ) >= thresholdInDays
      )
    },
    isTacticInPaidChannel(tactic: Tactic): boolean {
      return (
        this.$store.getters.paidChannelNames.indexOf(
          tactic.channelName.toLowerCase()
        ) > -1
      )
    },
    isTacticRelatedToDeselectedTag(tactic: Tactic): boolean {
      if(this.$store.getters.isTagFilterActive){
        if (tactic.tags.length == 0) {
          //Tag filters are selected and tactic has no tags assigned
          return true
        }
        // Tags have been selected, show any tactics that have any selected tags
        return tactic.tags.filter(
            (tag) =>
              this.$store.getters.selectedTags.filter(
                (selectedTag) => selectedTag.id.intID == tag.id.intID
              ).length > 0
          ).length
            ? false
            : true
      }
      return false
    },
    isTacticRelatedToDeselectedInitiative(tactic: Tactic): boolean {
      const relatedInitiatives = tactic.relatedInitiatives.map((mapping: RelatedInitiativeMapping) => mapping.initiativeId.intID)
      if (this.$store.getters.isInitiativeFilterActive && relatedInitiatives.filter((intID) => intID === 0).length) {
        //Initiative filters are selected and tactic has no initiatives assigned
        return true
      }
      if (
        this.$store.getters.isInitiativeFilterActive &&
        this.$store.getters.selectedInitiatives.length == 1
      ) {
        //Only one initiative has been selected, show any tactics that have selected initiative
        return relatedInitiatives.filter(
          (intID) =>
            this.$store.getters.selectedInitiatives.filter(
              (selectedInitiative) => selectedInitiative.id.intID == intID
            ).length > 0
        ).length
          ? false
          : true
      }
      //Multiple initiatives are selected, show tactics that have all of the selected initiatives
      return relatedInitiatives.filter(
        (intID) =>
          this.$store.getters.deselectedInitiatives.filter(
            (deselectedInitiative) => deselectedInitiative.id.intID == intID
          ).length > 0
      ).length
        ? true
        : false
    },
    isEventDuplicate(event: Tactic, subscription: Subscription): boolean {
      let returnValue = false
      const duplicateMappingsForTactic = subscription.duplicateEventsMap.filter(
        (duplicateMapping) => duplicateMapping.eventId == event.id.intID
      )
      // Only check for duplicates if there is a duplicate for the provided event
      if (duplicateMappingsForTactic.length) {
        duplicateMappingsForTactic.forEach((duplicateMapping) => {
          // Check if subscription with duplicate is visible
          const selectedSubscriptionWithDuplicate =
            this.$store.getters.selectedSubscriptions.find(
              (selectedSubscription) =>
                selectedSubscription.id.intID ==
                duplicateMapping.duplicateSubscriptionId
            )
          if (selectedSubscriptionWithDuplicate) {
            // Check if subscription with duplicate has a lower ID value than the provided subscription - event with lowest subscription ID will be shown
            if (
              duplicateMapping.duplicateSubscriptionId <
              duplicateMapping.subscriptionId
            ) {
              returnValue = true
            }
          }
        })
      }
      return returnValue
    },
    getAccordionTabNameForIndex(index: number): string {
      switch (index) {
        case 0:
          return 'subscriptions'
        case 1:
          return Channel.CHANNEL_NAME_INITIATIVES.toLowerCase()
        case 2:
          return 'monthlyfocus'
        case 3:
          return 'inmarketmedia'
        default:
          return ''
      }
    },
    onAccordionTabOpen(event) {
      window.localStorage.setItem(
        'mh-' + this.getAccordionTabNameForIndex(event.index),
        'true'
      )
    },
    onAccordionTabClose(event) {
      window.localStorage.setItem(
        'mh-' + this.getAccordionTabNameForIndex(event.index),
        'false'
      )
    },
    showTactic(tactic: Tactic) {
      const thisTacticIntID = tactic.originalTacticID ? tactic.originalTacticID.intID : tactic.id.intID
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/tactic/${thisTacticIntID}`,
      })
    },
    showInitiative(initiative: Tactic) {
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/initiative/${initiative.id.intID}`,
      })
    },
    showContextMenu({e, tactic}) {
      this.rightClickedTacticId = tactic.id
      this.rightClickedTacticIsInitiative = tactic.isInitiative
      ;(this.$refs.menu as Vue & {show: (e) => boolean}).show(e)
    },
    openTacticDetailWithCopyId() {
      this.$router.push({
        path:
          `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$route.params.planId}/${this.rightClickedTacticIsInitiative ? 'initiative' : 'tactic'}/0?copyof=${this.rightClickedTacticId.intID}`
      })
    },
    showTacticSummaryOverlay(info){
      // Show tactic summary overlay with delay on first hover, no delay on subsequent hovers
      const tacticSummaryDisplayDelay = this.isDisplayingSummaryTactic ? 0 : 2000
      this.currentSummaryTacticId = info.tactic.id
      if(info.tactic.originalTacticID){
        this.currentSummaryTacticId = info.tactic.originalTacticID
      }
      window.clearTimeout(this.currentSummaryTimeout)
      this.currentSummaryTimeout = window.setTimeout(()=>{
        this.isDisplayingSummaryTactic = true
        // @ts-expect-error
        this.$refs.tacticSummaryOverlay.show({...info.event, currentTarget: info.event.target})
      }, tacticSummaryDisplayDelay)
    },
    hideTacticSummaryOverlay(){
      // Hide tactic details overlay
      // @ts-expect-error
      this.$refs.tacticSummaryOverlay.hide()

      // Reset timeout after delay
      window.clearTimeout(this.currentSummaryTimeout)
      this.currentSummaryTimeout = window.setTimeout(()=>{
        this.isDisplayingSummaryTactic = false
        this.currentSummaryTacticId = {} as ID
        window.clearTimeout(this.currentSummaryTimeout)
      }, 3000)
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.overstory-container {
  .p-accordion-header {
    &.p-disabled {
      display: none;
    }
  }
  .tactics-row {
    .tactic-button {
      min-height: 3rem;

      &.subscription {
        border: 0.1rem solid #999999;
      }
    }
  }
  .flow-accordion .p-accordion-header:after {
    content: none;
  }
  .flow-accordion .events-container {
      border-right: 0;
  }
}
</style>