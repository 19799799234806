import UserSetup from '@/models/UserSetup'
import InitialResponse from '@/models/InitialResponse'
import axios from 'axios'

export default class AuthenticationService {
  public login(userData: UserSetup) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          'authenticate',
          {
            emailAddress: userData.email,
            password: userData.password,
          },
          {
            withCredentials: true
          }
        )
        .then(
          (response) => {
            window.localStorage.setItem('lgo', '0')
            resolve(InitialResponse.fromResponseObject(response.data))
          },
          (error) => {
            window.localStorage.setItem('lgo', '1')
            reject(error)
          }
        )
    })
  }

  public refreshAccessToken() {
    return new Promise((resolve, reject) => {
      axios
        .post(
          'refreshToken',
          {},
          {
            withCredentials: true
          }
        )
        .then(
          (response) => {
            window.localStorage.setItem('lgo', '0')
            resolve(InitialResponse.fromResponseObject(response.data))
          },
          (error) => {
            window.localStorage.setItem('lgo', '1')
            reject(error)
          }
        )
    })
  }

  public logout() {
    //Set "logged out" flag
    window.localStorage.setItem('lgo', '1')
  }

  public getSsoStatus(domain) {
    return new Promise((resolve, reject) => {
      axios.get(`user_domains?domain=${domain}`)
        .then(
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          }
        )
    })
  }
}
