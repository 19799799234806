<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Calendars of Interest"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button 
          @click="onCloseClick" 
          label="Save" 
          class="p-button p-mr-2" 
        />
        <Button
          @click="showCancelConfirmationPopUp"
          label="Close"
          class="p-button-outlined p-mr-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="plan-settings-modal-content" style="max-width: 100rem; margin: 0 auto;">
        <SetupTopView
          class="main_title onboarding-calendar-setup plan-settings-instructions"
          :subtitle="topViewContent.subtitle"
          title=""
        />
        <HowItWorks 
          class="p-mb-6" 
          imagePath="how-it-works-calendars.jpg" 
          slideshowPath="https://docs.google.com/presentation/d/e/2PACX-1vSawuxqBHokKv-U3yDVMK-PS5p0QxgiIEwiq3VJT_pIvN3BwD0s-DycS5i0SFfj96PoukQcUESyci4l/embed?start=false&loop=true&delayms=5000"
          :hideOverlay="true"
          />
        <div class="custom calendars-container">
          <SetupTopView class="title" :title="topViewContent2.title" />
          <ul class="setup-calendars-container calender-list">
            <li
              v-for="(calendar, i) in defaultCalendars"
              :key="calendar.key"
              class="calendars"
            >
              <PlanSettingsSubscriptionDetail
                :calendar="calendar"
                :index="i"
                :isEditable="false"
              />
            </li>
          </ul>
        </div>
        <div class="submit-suggestions-setting">
          <a
            class="text-link"
            href="mailto:hello@annumplanning.com?subject=Suggestion for default Calendars of Interest"
            target="_blank"
            >Submit calendar suggestions</a
          >
        </div>
        <div class="custom calendars-container">
          <SetupTopView
            class="title plan-settings-instructions"
            :title="topViewContent3.title"
            :subtitle="topViewContent3.subtitle"
          />
          <p class="plan-settings-instructions-small">
            Custom Calenders are available to all Plans in the Account
          </p>
          <ul class="setup-calendars-container calender-list">
            <li
              v-for="(calendar, i) in customCalendars"
              :key="calendar.key"
              class="calendars"
            >
              <PlanSettingsSubscriptionDetail
                :calendar="calendar"
                :index="i"
                :isEditable="true"
                @deleteSubscription="deleteSubscription"
              />
            </li>
          </ul>
          <div class="input-bottom-container">
            <InputText
              type="text"
              v-model="newCalendarUrl"
              class="input-bottom"
              placeholder="URL for calendar"
            />
            <div class="add-calendar" @click="handleCalendarCreate">
              <i class="pi pi-plus-circle p-mr-2"></i>
              Custom Calendar
            </div>
          </div>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Dialog from 'primevue/dialog'
import Plan from '@/models/Plan'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Role from '@/models/Role'
import Subscription from '@/models/Subscription'
import PlanSettingsSubscriptionDetail from '@/components/PlanSettingsSubscriptionDetail.vue'
import SetupTopView from '@/components/SetupTopView.vue'
import HowItWorks from '@/components/HowItWorks.vue'
import { isThisISOWeek } from 'date-fns'

Vue.component('Dialog', Dialog)

export default Vue.extend({
  name: 'PlanSetingsSubscriptions',
  components: {
    ViewHeader,
    ViewMain,
    PlanSettingsSubscriptionDetail, 
    SetupTopView, 
    HowItWorks
  },
  data: () => {
    return {
      selectedDefaultCalendars: [] as Subscription[],
      selectedCustomCalendars: [] as Subscription[],
      newCalendarUrl: '',
      topViewContent: {
        title: 'Calendars of Interest',
        subtitle: 'Add external calendars to your Plan to pull holidays, observances and other important events into your views.<br/><br/>Click the box to activate. Then all team members can use the Filters pane to toggle them on and off. View more <a class="text-link" target="_blank" href="https://www.annumplanning.com/resources/guides/calendars-of-interest/">detailed instructions</a>.',
      },
      topViewContent2: {
        title: 'Standard Calendars',
      },
      topViewContent3: {
        title: 'Custom Calendars',
        subtitle: 'Add a publicly shared event calendar using its iCal/.ics feed URL. Online event calendars often have a button that says either subscribe, export, or share. Click to find the URL. Then copy the link and paste it in the field below for a synced import.<br/><br/>Learn how to <a class="text-link" href="https://www.annumplanning.com/resources/guides/create-a-shared-calendar/">create your own publicly shared calendar to import</a>.',
      },
      customCalendars: [] as Subscription[],
    }
  },

  created() {
    this.customCalendars = this.$store.getters.allSubscriptions.filter(
      (subscription) => subscription.organization !== "default"
    )
  },
  computed: {
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    canUserManagePlanUsersChannelsSubscriptions(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.plan.id) <=
        Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    defaultCalendars(): Subscription[] {
      return this.$store.getters.allSubscriptions.filter(
        (subscription) => subscription.organization === "default"
      )
    },
  },

  mounted() {
    // Update selected calendars
    this.defaultCalendars.forEach((calendar) => {
      if (
        this.plan.subscriptionIds.find(
          (planCalendarId) => planCalendarId.intID === calendar.id.intID
        )
      ) {
        calendar.selected = true
      } else {
        calendar.selected = false
      }
    })
    this.customCalendars.forEach((calendar) => {
      if (
        this.plan.subscriptionIds.find(
          (planCalendarId) => planCalendarId.intID === calendar.id.intID
        )
      ) {
        calendar.selected = true
      } else {
        calendar.selected = false
      }
    })
  },
  beforeDestroy() {
    this.$store.getters.allSubscriptions.forEach((calendar) => {
      calendar.selected = false
    })
  },
  methods: {
    onCloseClick() {
      //Update current plan with new subscription list
      this.plan.subscriptionIds = this.$store.getters.allSubscriptions.filter(
        (calendar) => calendar.selected === true
      ).map((subscription)=>subscription.id)
      this.plan.accountId = this.$store.getters.currentAccount.id
      this.$store.getters.services.plans.update(this.plan).then(
        (plan) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Plan updated.',
            life: 3000,
          })
          this.$store.dispatch('updateCurrentPlan', plan)
          this.$emit('complete')
          this.$router.back()
        },
        (error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error updating plan.',
          })
        }
      )
    },
    handleCalendarCreate() {
      if (this.newCalendarUrl) {
        const newSubscription = Subscription.fromResponseObject({
          calendarPath: this.newCalendarUrl,
          name: 'New Calendar',
          account: this.$store.getters.currentAccount.id.intID,
          organization: this.$store.getters.currentAccount.contactCompanyName
        })
        
        this.$store.getters.services.accounts
          .createSubscription(newSubscription)
          .then((newSubscription) => {
            this.newCalendarUrl = ''
            newSubscription.opened = true

            this.customCalendars.push(newSubscription)
            this.$store.getters.allSubscriptions.push(newSubscription)
          })
      } else {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must add url for the calendar first',
        })
      }
    },
    showCancelConfirmationPopUp(event) {
      this.$emit('complete')
      this.$store.dispatch('refreshCurrentPlan')
      this.$router.back()
    },
    sendCalendarSuggestions() {
      const email = 'hello@annumplanning.com'
      const subject = 'Suggestion for default Calendars of Interest'
      document.location.href = 'mailto:' + email + '?subject=' + subject
    },
    deleteSubscription(calendarId) {
      this.customCalendars = this.customCalendars.filter(
        (subscription) => subscription.id.intID !== calendarId
      )
      this.$store.dispatch('updateSubscription')
    },
  },
})
</script>