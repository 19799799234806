<template>
  <div class="onboarding-tutorial-container">
    <div class="close-container">
      <i class="pi pi-times" @click="onCloseClick()"></i>
    </div>
    <div class="plan-settings-modal-header-tutorial">
      <h2>Quick Start Tutorial</h2>
      <div class="tutorial-content">
        <p>We've outlined five actions that will give you a quick feel for how our strategic omnichannel planning calendar works.</p>
        <p class="sub-note">Click below to open our tutorial in a new tab so you can follow along while planning.</p>
        <Button @click="onOpenTutorialClick()" class="view-tutorial-button">View Tutorial</Button>
        <p class="foot-note">For full detailed instructions click on Role Tutorial: Account Owner above. Find additional resources under Help in the bottom left corner.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TutorialOnboarding',
  methods: {
    onCloseClick(){
      this.$emit('closeModal')
    },
    onOpenTutorialClick(){
      window.open('https://www.annumplanning.com/resources/guides/quick-start-guide/', '_blank')
    }
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.onboarding-tutorial-container {
  h2 {
    @include font-normal;
    font-size: 4rem;
  }
  p {
    @include font-light;
    font-size: 2rem;

    &.sub-note {
      font-size: 1.4rem;
    }
    &.foot-note {
      font-size: 1.2rem;
    }
  }
  .tutorial-content {
    max-width: 60rem;
    margin: 1.5rem auto 6rem;
  }
  .view-tutorial-button {
    margin: 4rem 0 3rem;
    font-size: 1.5rem;
  }
  .close-container {
    background-color: #f9f9f9;
    text-align: right;
    padding-right: 1rem;
    padding-top: 1rem;
    cursor: pointer;
  }
}
</style>
