<template>
  <div class="login-error-container logged-out-container p-d-flex p-flex-column p-jc-center p-ai-center">
    <h3>The email and password you entered do not match our records. Please double-check and try again.</h3>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LogInError',
  data: () => {
    return {}
  },
  mounted() {
    // Potentially show different error messages based on URL param
  },
})
</script>

<style lang="scss">
.login-error-container {
  h3 {
    font-size: 1.5rem;
  }
}
</style>