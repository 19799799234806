<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Strategic Priorities"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
        @click="()=>{
            $emit('complete')
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="content plan-settings-modal-content" style="max-width: 100rem; margin: 0 auto;">
        <div v-if="loading">
          <p style="margin-left: 20px">Loading...</p>
        </div>
        <div v-else>
          <div class="p-field">
            <div class="p-d-flex top-items">
              <p class="plan-settings-instructions">
                Strategic Priorities are evergreen elements of your marketing strategy that are addressed by your marketing plan. Default categories include objectives, target segments, and journey phases. Custom categories can be created for business units, regions, content pillars, etc.<br/><br/>
                Enter your Strategic Priorities, then tag Initiatives and Tactics, and use Filters to create isolated views to check cross-channel coverage. View <a class="text-link" target="_blank" href="https://www.annumplanning.com/resources/guides/strategic-priorities/">detailed instructions</a>.
              </p>
            </div>
          </div>
          <HowItWorks
            class="p-mb-6"
            imagePath="how-it-works-strategic-priorities.jpg"
            slideshowPath="https://docs.google.com/presentation/d/e/2PACX-1vREe1PDII_06MfJjSKd3bIxHJFvdGulJcwiWLGfVp_qElacJkKoy20ZtzUYhIiCIRIwRNl4jKLvmp-G/embed?start=false&loop=true&delayms=5000"
            :hideOverlay="true"
          />
          <div
            v-for="category in tagCategories"
            :key="category.id"
            class="category plan-category"
          >
            <div class="category__title">
              <h3>{{ category.name }}</h3>
            </div>
            <Container
              class="category__tags"
              @drop="(dropResults) => onTagDrop(dropResults, category)"
              drag-handle-selector=".tag__drag-icon"
            >
              <Draggable
                v-for="(tag, i) in category.tags"
                :key="`tag-${tag.id.intID}-${i}`"
                :class="{
                  'tag': true,
                  'is-nested': tag.isNested,
                  'is-lead': tag.isLead,
                }"
              >
                <p v-if="areTagsReorderable" class="tag__drag-icon">☰</p>
                <p class="tag__title" v-if="!tag.editing">
                  <template
                    v-if="
                      isLeadPlan && tag.isNested && tag.abbreviatedPlanName !== ''
                    "
                  >
                    {{ tag.abbreviatedPlanName }} > {{ tag.title }}
                  </template>
                  <template v-else>
                    {{ tag.title }}
                  </template>
                  <template v-if="$store.getters.currentUser.isSuperUser">
                    <span class="id-value"> ({{ tag.id.intID }})</span>
                  </template>
                </p>
                <input
                  type="text"
                  v-else
                  v-model="tag.editingValues.name"
                  class="tag__edit-title"
                />
                <div v-if="getIsTagEditable(tag)" class="tag__icons">
                  <span v-if="!tag.editing">
                    <i
                      class="pi pi-pencil"
                      @click="
                        () => {
                          tag.editing = true
                        }
                      "
                    />
                    <i
                      class="pi pi-trash delete-icon"
                      @click="(e) => handleDeleteTag(e, tag.id.intID)"
                    />
                  </span>
                  <span v-else>
                    <i
                      class="pi pi-check"
                      @click="() => handleTagEdit(category.name, tag)"
                    />
                    <i
                      class="pi pi-times"
                      @click="
                        () => {
                          tag.editing = false
                        }
                      "
                    />
                    <i
                      class="pi pi-trash delete-icon"
                      @click="(e) => handleDeleteTag(e, tag.id.intID)"
                    />
                  </span>
                </div>
              </Draggable>
            </Container>
          </div>
          <div class="new-tag p-d-flex p-jc-center">
            <div class="new-tag__category">
              <div class="p-mb-1">
                <label for="newTagCategory">Category</label>
              </div>
              <Dropdown
                v-model="newTag.category"
                :options="tagCategoryOptions"
                optionLabel="name"
                OrderBY="id"
                optionValue="name"
                @change="handleTagSelect"
                name="newTagCategory"
              />
            </div>

            <div
              class="new-tag__name"
              v-if="newTag.category === '[ New Category ]'"
            >
              <div class="p-mb-1">
                <label for="newTagName">New Category Name</label>
              </div>
              <InputText
                type="text"
                v-model="newTag.categoryName"
                name="newTagName"
              />
            </div>
            <div class="new-tag__name">
              <div class="p-mb-1"><label for="newTagName">Tag Name</label></div>
              <InputText v-model="newTag.name" name="newTagName" />
            </div>
            <div class="new-tag__add" @click="handleAddTag">
              <i class="pi pi-plus-circle p-mr-1" /> Strategic Priority
            </div>
          </div>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Tag from '@/models/Tag'
import Plan from '@/models/Plan'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import {Container, Draggable} from 'vue-smooth-dnd'
import {applyDrag} from '@/utils/applyDrag'
import ID from '@/models/ID'
import HowItWorks from '@/components/HowItWorks.vue'
import TagCategory from '@/models/TagCategory'

Vue.component('Button', Button)
Vue.component('Dropdown', Dropdown)

export default Vue.extend({
  name: 'PlanSettingsTags',
  components: {
    ViewHeader,
    ViewMain,
    Container,
    Draggable,
    HowItWorks,
  },
  data: () => ({
    loading: true,
    topViewContent: {
      title: 'Strategic Priorities',
      infoText:
        'Enter key elements of your Strategy to tag and filter Tactics in the calendar views.',
    },
    newTag: {
      name: null,
      category: null,
      categoryName: null,
    },
    tagCategoriesUpdateItterator: 0 as number,
  }),
  async created() {
    await this.$store.dispatch('refreshCurrentPlan').then(()=>{
      this.loading = false
    })
  },
  computed: {
    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    isNestedPlan(): boolean {
      return this.$store.getters.currentPlan.parentId.intID !== 0 ? true : false
    },
    tagCategories(): TagCategory[] {
      this.tagCategoriesUpdateItterator
      return this.$store.getters.currentPlanTagCategories
    },
    tagCategoryOptions(): TagCategory[] {
      // Add all defaults and filter out duplicates
      return this.tagCategories.concat([
        new TagCategory(1001, 'Objective'),
        new TagCategory(1002, 'Target Segment'),
        new TagCategory(1003, 'Journey Phase'),
        new TagCategory(1000, '[ New Category ]')
      ]).filter((tagCategory, index, self)=>
        index === self.findIndex((t) => (
          t.name === tagCategory.name
        ))
      )
    },
    areTagsReorderable(): boolean {
      return this.$store.getters.currentPlanPermissionLevel <= 250 && (this.isLeadPlan || (!this.isLeadPlan && !this.isNestedPlan))
    }
  },
  methods: {
    getIsTagEditable(tag: Tag): boolean {
      // always return true based on direction from ticket 547 - JV 09.02.22
      // return true

      // Prevent lead tag editing in nested plans and nested plan editing in lead plan based on direction from ticket 894 - JV 09.05.23
      return (
        (this.isLeadPlan && !tag.isNested) ||
        (!this.isLeadPlan && tag.isNested) ||
        (!tag.isLead && !tag.isNested)
      )
    },
    handleTagSelect() {
      if (this.newTag.category !== '[ New Category ]') {
        this.newTag.categoryName = this.newTag.category
      } else {
        this.newTag.categoryName = null
      }
    },
    handleAddTag() {
      const {name, category, categoryName} = this.newTag

      if (!category) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must select category first',
        })
        return
      }

      if (!name) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must add tag name first',
        })
        return
      }

      const newTag = Tag.fromResponseObject({
        text: `${categoryName ? categoryName : category}:${name}`,
        plan: this.plan.id.intID,
      })

      if (!categoryName) {
        const categoryIndex = this.tagCategories.findIndex(
          (cat) => cat.name === category
        )
        newTag.orderIndex = this.tagCategories[categoryIndex].tags.length
      }

      this.$store.getters.services.tags.create(newTag).then((newDBTag) => {
        if (this.isLeadPlan) {
          newDBTag.isLead = true
        }
        if (this.isNestedPlan) {
          newDBTag.isNested = true
        }
        this.plan.tags.push(newDBTag)
        this.newTag = {
          name: null,
          category: null,
          categoryName: null,
        }
        this.tagCategoriesUpdateItterator++
      })
    },

    onTagDrop(result, category) {
      const sortedTags = applyDrag(category.tags, result)
      category.tags = sortedTags
      JSON.parse(JSON.stringify(sortedTags)).map((orderedTag, i) => {
        const newTag = new Tag(
          ID.fromResponseObject(orderedTag.id.intID, 'tags'),
          orderedTag.text,
          ID.fromResponseObject(orderedTag.planId.intID, 'plans'),
          orderedTag.isLead,
          orderedTag.isNested,
          i,
          orderedTag.abbreviatedPlanName
        )
        return (this as any).handleTagEdit(category.name, newTag)
      })
      this.tagCategoriesUpdateItterator++
    },

    handleTagEdit(category: string, tag: Tag) {
      const newTag: Tag = new Tag(
        ID.fromResponseObject(tag.id.intID, 'tags'),
        `${category}:${tag.editingValues.name}`,
        ID.fromResponseObject(tag.planId.intID, 'plans'),
        tag.isLead,
        tag.isNested,
        tag.orderIndex,
        tag.abbreviatedPlanName
      )

      return this.$store.getters.services.tags
        .update(newTag)
        .then((updatedTag) => {
          updatedTag.isLead = newTag.isLead
          updatedTag.isNested = newTag.isNested
          updatedTag.abbreviatedPlanName = newTag.abbreviatedPlanName
          const newTags = this.plan.tags.map((tag) =>
            tag.id.intID === updatedTag.id.intID ? updatedTag : tag
          )
          this.plan.tags = newTags
          this.$store.dispatch('refreshCurrentPlan').then(() => {
            return updatedTag
          })
          this.tagCategoriesUpdateItterator++
        })
    },

    handleDeleteTag(e, tagId: number) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this Strategic Priority? All associated Tactic tags will be deleted along with it.`,
        target: e.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.tags.delete([tagId]).then(() => {
            const newTags = this.plan.tags.filter((t) => t.id.intID !== tagId)
            this.plan.tags = newTags
            this.tagCategoriesUpdateItterator++
          })
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },

    handleClickContinue() {
      // TODO: call API to update this.$store.getters.currentPlan object
      this.$emit('setup-step-complete')
    },
  },
})
</script>