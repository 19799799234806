<template>
  <div
    id="app"
    class="p-d-flex"
    :class="{'global-nav-collapsed': $store.getters.isGlobalNavCollapsed, 'print-dialog-open': $store.getters.isPrintDialogOpen}"
    :data-app-version="versionNumber"
  >
  <BlockUI :blocked="$store.getters.isUIBlocked" :fullScreen="true">
    <UserFeedback v-if="$store.getters.isUserLoggedIn" />
    <ConfirmPopup class="global-p-confirm-popup" />
    <Toast :baseZIndex="9999" position="bottom-right" />
    <template v-if="$store.getters.isAppInitialized">
      <template v-if="$store.getters.isUserLoggedIn">
        <router-view name="main" :key="$route.fullPath" />
        <GlobalNavigation />
      </template>
      <template v-else>
        <router-view name="loggedOut" />
      </template>
    </template>
    <template v-else>
      <img
        class="global-init-logo"
        alt="Annum logo"
        src="@/assets/logo-global.jpeg"
        width="126"
        height="54"
      />
      <div class="global-init-spinner-container p-d-flex p-ai-center">
        <ProgressSpinner strokeWidth="3" />
      </div>
    </template>
    <div class="global-api-spinner-container p-d-flex p-ai-center" :class="{'show': allowApiLoadingSpinnerDisplay && isApiRequestInProgress}">
      <ProgressSpinner strokeWidth="3" />
    </div>
    <div class="small-viewport-blocker">
      <img 
        alt="Annum logo"
        src="@/assets/logo-global.jpeg"
        width="126"
        height="54"
      />
      <h2>This application is not supported on tablets or smartphones. It was designed for omnichannel planning which is best executed on a laptop.</h2>
      <p>If you are working on a laptop, this message is shown when the browser's window is under 800px wide or 500px tall. Adjust the window in the following ways:</p>
      <ol>
        <li>Make sure the browser window is full screen wide and full screen tall.</li>
        <li>
          Make sure your browser magnification is at 100% or only slightly over.
          <ul>
            <li>On a PC: Press CTRL and the + key to zoom in. Press CTRL and the - key to zoom out.</li>
            <li>On a Mac: Press Shift, Command, and the + key to zoom in. Press Shift, Command, and the - key to zoom out.</li>
          </ul>
        </li>
      </ol>
    </div>
  </BlockUI>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios'
import GlobalNavigation from '@/components/GlobalNavigation.vue'
import UserFeedback from '@/components/UserFeedback.vue'
import Toast from 'primevue/toast'
import ProgressSpinner from 'primevue/progressspinner'
import PrimeVue from 'primevue/config'
import ConfirmPopup from 'primevue/confirmpopup'
import BlockUI from 'primevue/blockui'

import 'primevue/resources/themes/nova/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import { AlertMessageSeverity } from '@/models/AlertMessage'

Vue.component('ConfirmPopup', ConfirmPopup)
Vue.component('Toast', Toast)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('UserFeedback', UserFeedback)
Vue.component('BlockUI', BlockUI)

Vue.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 0,
    dayNames: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'mm/dd/yy',
    weekHeader: 'Wk',
  },
})

export default Vue.extend({
  name: 'AnnumApp',
  components: {
    GlobalNavigation,
  },
  data: () => {
    return {
      allowApiLoadingSpinnerDisplay: false as boolean,
      allowApiLoadingSpinnerDisplayTimeout: {},
      allowApiLoadingSpinnerDisplayTimeoutDuration: 500 as number,
      isApiRequestInProgress: false as boolean,
      apiRequestProgressTimeout: {},
      apiRequestProgressTimeoutDuration: 500 as number,
    }
  },
  computed: {
    versionNumber() {
      return process.env.VUE_APP_VERSION || '0'
    },
    maintenanceModeFlag() {
      return process.env.VUE_APP_MAINTENANCE_MODE || 'false'
    },
  },
  watch: {
    $route(to, from) {
      //Update page title
      if (to.meta.title) {
        document.title = to.meta.title
      }

      // Redirect to message view if maintenance mode flag is true
      if(this.maintenanceModeFlag == 'true'){
        this.$router.replace('/maintenance-mode').catch(err=>{
          // Swallow redundant navigation error
        })
        return false
      }

      // Store account ID from route in AppStore
      if (this.$route.params.accountId) {
        this.$store.commit(
          'updateRouteAccountIntId',
          Number(this.$route.params.accountId)
        )
      }

      //Check for valid refresh token on all route changes to force logout on all tabs of the same browser
      this.$store.dispatch('checkLoggedInStatus')

      //Apply filters from query params after initialization
      if (this.$route.query) {
        this.$store.dispatch('readQueryParams', this.$route.query)
      }

      // Update global route history
      this.$store.commit('addRouteToRouteHistory', to)
    },
  },
  created: function () {
    //Update page title
    document.title = (this.$route as any).meta.title
      ? (this.$route as any).meta.title
      : 'Annum'

    //Initialize app
    this.$store.dispatch('initApp', {currentRoute: this.$router.currentRoute}).then(
      () => {
        // Redirect to message view if maintenance mode flag is true
        if(this.maintenanceModeFlag == 'true'){
          this.$router.replace('/maintenance-mode').catch(err=>{
            // Swallow redundant navigation error
          })
          return false
        }

        //Re-route to LogIn view if not logged in and not accessing a publicly-accessible route
        if (this.$store.getters.isUserLoggedIn) {
          if (!this.$store.getters.currentAccount.isActive) {
            this.$router.replace('/plans')
          } else if(this.$router.currentRoute.path === '/sign-in'){
            this.$router.replace('/')
          }else{
            // Allow routing to current path
          }
        } else {
          if(!this.$router.currentRoute?.meta?.isPublic){
            this.$router.replace('/sign-in')
          }else{
            // Stay on sign-in route
          }
        }
      },
      (error) => {
        console.warn('App.created() - initApp error:', error)
        if(error.response.status === 401){
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.warn,
            summary: `You have been automatically logged out. Please log in again.`,
          })
          this.$router.replace('/sign-in')
        }else{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: error,
          })
          this.$router.replace(this.$router.currentRoute.path === '/sign-in' ? '/' : '/sign-in')
        }
        this.$store.commit('updateIsUIBlocked', false)
      }
    )

    //Set values in AppStore based on query params
    if (
      Object.keys(this.$route.query).length &&
      this.$router.currentRoute.path.indexOf('/change-password') < 0 &&
      this.$router.currentRoute.path.indexOf('/set-password') < 0 &&
      this.$router.currentRoute.path.indexOf('search') < 0
    ) {
      this.$store.dispatch('readQueryParams', this.$route.query)
    }

    // Set up interceptor for all API requests and responses to show/hide loading spinner
    axios.interceptors.request.use(
      (config) => {

        // Redirect to message view if maintenance mode flag is true
        if(this.maintenanceModeFlag == 'true'){
          this.$router.replace('/maintenance-mode').catch(err=>{
            // Swallow redundant navigation error
          })
          return {}
        }

        this.isApiRequestInProgress = true

        this.allowApiLoadingSpinnerDisplayTimeout = window.setTimeout(() => {
          if (this.isApiRequestInProgress) {
            this.allowApiLoadingSpinnerDisplay = true
          } else {
            this.allowApiLoadingSpinnerDisplay = false
          }
        }, this.allowApiLoadingSpinnerDisplayTimeoutDuration)

        return {
          ...config,
          onUploadProgress: this.onApiProgress, // Upload progress
          onDownloadProgress: this.onApiProgress, // Download progress
        }
      },
      (error) => {
        this.onApiError()
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use((response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      if(response?.status === 204){
        this.isApiRequestInProgress = false
        this.allowApiLoadingSpinnerDisplay = false
      }
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      return Promise.reject(error);
    });

    // Handle print events to apply class to wrapper
    window.addEventListener('beforeprint', () => {
      this.$store.commit('updateIsPrintDialogOpen', true)
    })
    window.addEventListener('afterprint', () => {
      this.$store.commit('updateIsPrintDialogOpen', false)
    })
  },
  methods: {
    onApiProgress(progressEvent) {
      const totalResponseSize = progressEvent.lengthComputable ? progressEvent.total : parseInt(progressEvent.currentTarget.getResponseHeader('x-file-size'));
      if(totalResponseSize && totalResponseSize >= 0){
        // Update isApiRequestInProgress based on request progress
        this.isApiRequestInProgress = Math.floor(progressEvent.loaded / totalResponseSize) < 1
        this.allowApiLoadingSpinnerDisplay = this.isApiRequestInProgress
      }else{
        // Set timeout to change isApiRequestInProgress to false until next progress event comes in
        this.apiRequestProgressTimeout = window.setTimeout(() => {
          this.isApiRequestInProgress = false
          this.allowApiLoadingSpinnerDisplay = false
        }, this.apiRequestProgressTimeoutDuration)
      }
    },
    onApiError() {
      this.isApiRequestInProgress = false
      this.allowApiLoadingSpinnerDisplay = false
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/App.scss';

.global-p-confirm-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.global-init-logo {
  position: fixed;
  top: calc(50% - 2rem);
  left: calc(50% - 3.5vw);
  max-width: 7vw;
}

.global-init-spinner-container,
.global-api-spinner-container {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 0.5rem;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.4rem;
  opacity: 0;
  transition: opacity 0.3s 1s;

  &.show {
    opacity: 1;
    transition: opacity 0s;
  }

  p {
    margin: 0 2rem 0 0;
  }
  .p-progress-spinner {
    width: 4rem;
    height: 4rem;
  }
}
.global-init-spinner-container {
  right: 50%;
  bottom: calc(50% - 10rem);
  transform: translateX(calc(50% + 0.25rem));
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $tacticColorCollateral;
  }
  40% {
    stroke: $tacticColorSocialMedia;
  }
  66% {
    stroke: $tacticColorDirectMail;
  }
  80%,
  90% {
    stroke: $tacticColorPR;
  }
}

// Move toast container out of the way of global API spinner
#app .p-toast-bottom-right {
  bottom: 6rem;
}

.global-view-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
}

.view-wrapper {
  //.view-wrapper div located in individual view components
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}

.tooltip-color {
  color: gray;
}

// .view-fade-transition-enter-active {
//   transition: opacity 0.3s 0.2s;
//   transition-timing-function: easeInOutSine;
// }
// .view-fade-transition-leave-active {
//   transition: opacity 0.2s;
//   transition-timing-function: easeOutQuint;
// }
// .view-fade-transition-enter,
// .view-fade-transition-leave-to {
//   opacity: 0;
// }
</style>
