import ID from './ID'

export default class RelatedInitiativeMapping {
  constructor(
    public tacticId: ID = new ID(),
    public initiativeId: ID = new ID(),
    public initiativeTitle: string = '',
    public tacticTypeId: ID = new ID(),
    public tacticTypeName: string = ''
  ) {}

  public get key() {
    return 'related-initiative-mapping-' + this.tacticId.intID.toString() + '-' + this.initiativeId.intID.toString()
  }

  public static fromResponseObject(tacticIntId, mappingObj): RelatedInitiativeMapping {
    if (!tacticIntId || !mappingObj) return new RelatedInitiativeMapping()
    return new RelatedInitiativeMapping(
      ID.fromResponseObject(tacticIntId, 'tactics'),
      ID.fromResponseObject(mappingObj.initiativeId, 'tactics'),
      mappingObj.initiativeTitle,
      ID.fromResponseObject(mappingObj.tacticTypeId, 'tactic_types'),
      mappingObj.tacticTypeName
    )
  }

  public forRequestObject() {
    return {
      initiativeId: this.initiativeId.intID,
      initiativeTitle: this.initiativeTitle,
      tacticTypeId: this.tacticTypeId.intID,
      tacticTypeName: this.tacticTypeName
    }
  }
}
