<template>
  <div class="view-wrapper" :class="{'is-nested-plan': !isLeadPlan}">
    <ViewHeader
      title="Channels"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <Button
        @click="()=>{
            $emit('complete')
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="plan-settings-modal-content" style="max-width: 100rem; margin: 0 auto;">
        <div class="p-field">
          <div class="p-d-flex top-items">
            <p class="plan-settings-instructions">
              Customize Channels to suit your needs.<br /><br />
              Click on the caret icon to the far right of the Channel name to configure Tactic Types, Platforms, Custom Fields of data, and more. Scroll to the bottom of the list to add a Channel. View <a class="text-link" target="_blank" href="https://www.annumplanning.com/resources/guides/channel-set-up/">detailed instructions</a>.
            </p>
            <p><span class="plan-settings-instructions-small">A Channel must have at least one Tactic Type in order to enter a Tactic within that Channel into the Plan.</span></p>
          </div>
          <HowItWorks 
            class="p-mb-6"
            imagePath="how-it-works-channels-combined.jpg" 
            slideshowPath="https://docs.google.com/presentation/d/e/2PACX-1vRKMWo8pLimq9hEf5_XLcwLG7nkjwaZ5BGz4mFlSknnJ66U-EGcFE3zld7WtCYt05FGShGXnF8ZmHG_/embed?start=false&loop=true&delayms=5000"
            :hideOverlay="true"
            />
        </div>
        <div class="p-d-flex p-jc-end p-ai-center">
            <p class="p-mr-2">Notes:</p>
            <span
                v-tooltip.bottom="'Notes are fields designed for entering information related to a Tactic or Initiative as rich text. Within Notes you can format text, add bullets and numbered lists, create links, and include graphics or images.<br/><br/>Rich text fields are unstructured data and as such cannot ne mapped for integration with another app or exported via CSV, Our Custom Fields, available to be added at the Tactic Type level, have been designed for that purpose.'"
                class="pi pi-question-circle tooltip-color p-mr-2"
              ></span>
            <div class="checkbox-container p-mr-2">
              <Checkbox
                :binary="true"
                v-model="tacticSummaryOn"
                class="p-mr-2 checkbox"
                @change="() => handleToggleTacticSummary(true)"
              />
              <label for="budgetOn">On</label>
            </div>
            <div class="checkbox-container p-mr-2">
              <Checkbox
                :binary="true"
                v-model="tacticSummaryOff"
                class="p-mr-2 checkbox"
                @change="() => handleToggleTacticSummary(false)"
              />
              <label for="budgetOff">Off</label>
            </div>
        </div>
        <div class="channels-container">
          <template v-if="isLeadPlan || (!isLeadPlan && !isNestedPlan)">
            <Container
              @drop="onChannelDragEnd"
              drag-handle-selector=".drag-icon--channel"
            >
              <Draggable
                v-for="(channel, channelIndex) in filteredChannels"
                :key="channel.id.intID"
              >
                <PlanSettingsChannelDetail
                  class="setup-channel"
                  :class="{'is-empty': channel.name === initiativesChannelName}"
                  :channel="channel"
                  :channelIndex="channelIndex"
                  :canUserEdit="canCurrentUserEditChannel(channel)"
                />
              </Draggable>
            </Container>
          </template>
          <template v-else>
            <PlanSettingsChannelDetail
              v-for="(channel, channelIndex) in filteredChannels"
              class="setup-channel"
              :class="{'is-empty': channel.name === initiativesChannelName}"
              :key="channel.id.intID"
              :channel="channel"
              :channelIndex="channelIndex"
              :canUserEdit="canCurrentUserEditChannel(channel)"
            />
          </template>
        </div>
        <div class="add-channel" v-if="canUserManagePlanChannels">
          <div class="add-field__inputs">
            <ColorPicker
              @colorSelected="
                (color, e) => {
                  currentChannel.uiColor = color
                }
              "
              :selectedColor="currentChannel.uiColor"
            />
            <InputText
              id="name"
              type="text"
              v-model="currentChannel.name"
              placeholder="New Channel"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
            />
          </div>

          <div class="add-field__btn p-mr-4" @click="() => addChanel()">
            <span class="pi pi-plus-circle"></span>
            <span>Channel</span>
          </div>
        </div>
        <div class="universal-tactic-fields-container">
          <DataTable
            :editingRows.sync="universalFieldEditingRows" 
            :value="currentUniversalFields" 
            editMode="row" 
            dataKey="key" 
            :autoLayout="true"
            class="categories-table"
            @row-edit-init="onUniversalFieldRowEditInit"
            @row-edit-save="onUniversalFieldRowEditSave"
          >
            <Header>
              <div class="p-d-flex p-ai-center">
                <h2>Universal Tactic Fields</h2>
                <span
                  v-tooltip.bottom="'Use Universal Tactic Fields to add additional fields of data for utilization across every type of Tactic. They will appear in the Tactic detail and the Table view.'"
                  class="pi pi-question-circle tooltip-color p-ml-2"
                ></span>
              </div>
            </Header>
            <Column field="name" bodyClass="data-column-40">
              <template #body="slotProps">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <div>
                    {{ slotProps.data.name }}
                  </div>
                </div>
              </template>
              <template #editor="slotProps">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <InputText v-model="slotProps.data.name" />
                </div>
              </template>
            </Column>
            <Column field="type" bodyClass="data-column-40">
              <template #body="slotProps">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <div>
                    {{ getTacticFieldTypeLabelForCode(slotProps.data.type) }}
                  </div>
                </div>
              </template>
              <template #editor="slotProps">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <Dropdown
                    v-model="slotProps.data.type"
                    :options="tacticFieldTypes"
                    placeholder="Type"
                    optionLabel="label"
                    optionValue="code"
                  />
                </div>
              </template>
            </Column>
            <Column 
              :rowEditor="true" 
              bodyClass="data-column-10" 
              v-if="canUserManagePlanChannels"
            ></Column>
            <Column 
              bodyClass="data-column-10" 
              v-if="canUserManagePlanChannels"
            >
              <template #body="slotProps">
                <Button 
                  icon="pi pi-trash" 
                  severity="danger" 
                  class="p-button-text p-button-danger"
                  @click="onUniversalFieldDelete($event, slotProps.data)" 
                />
              </template>
              <template #editor>
                <Button 
                  icon="pi pi-trash" 
                  severity="danger" 
                  class="p-button-text p-button-danger"
                  disabled
                />
              </template>
            </Column>
          </DataTable>

          <div class="add-initiative-category-container" v-if="canUserManagePlanChannels">
            <div class="p-field">
              <InputText 
                v-model="newUniversalFieldName" 
                placeholder="New Universal Field"
                />
            </div>
            <div class="p-field">
              <Dropdown
                v-model="newUniversalFieldType"
                :options="tacticFieldTypes"
                placeholder="Type"
                optionLabel="label"
              />              
            </div>
            <Button 
              label="New Universal Field"
              icon="pi pi-plus-circle" 
              class="p-button-text"
              @click="addUniversalField()" 
            />
          </div>
        </div>
      </div>

      <!-- Display data to super user for use in integration platforms -->
      <div v-if="isCurrentUserSuperUser" class="p-mt-4">
        <h2>Integration Data</h2>
        <h3>Account: {{ $store.getters.currentAccount.contactCompanyName }} - {{ $store.getters.currentAccount.id.intID }}</h3>
        <h3>Plan: {{ $store.getters.currentPlan.name }} - {{ $store.getters.currentPlan.id.intID }}</h3>
        <table class="integration-data-table" cellspacing="0">
          <tr>
            <th>Annum TacticType ID</th>
            <th>Annum Tactic Type</th>
            <th>Annum Channel</th>
            <th>Annum Plan</th>
          </tr>
          <template v-for="(channel) in $store.getters.currentPlan.channels">
            <template v-for="(type) in channel.tacticTypes">
              <tr :key="type.key">
                <td>{{ type.id.intID }}</td>
                <td>{{ type.name }}</td>
                <td>{{ channel.name }}</td>
                <td>
                  {{ channel.planId.intID }}
                  <template v-if="channel.abbreviatedPlanName"> - {{ channel.abbreviatedPlanName }}</template>
                </td>
              </tr>
            </template>
          </template>
        </table>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Plan from '@/models/Plan'
import ID from '@/models/ID'
import Channel from '@/models/Channel'
import Role from '@/models/Role'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import PlanSettingsChannelDetail from '@/components/PlanSettingsChannelDetail.vue'
import {Container, Draggable} from 'vue-smooth-dnd'
import TacticType from '@/models/TacticType'
import TacticField, {TacticFieldType} from '@/models/TacticField'
import TacticPlatform from '@/models/TacticPlatform'
import ColorPicker from '@/components/ColorPicker.vue'
import HowItWorks from '@/components/HowItWorks.vue'
import Tooltip from 'primevue/tooltip'

export default Vue.extend({
  name: 'PlanSettingsChannels',
  components: {
    ViewHeader,
    ViewMain,
    PlanSettingsChannelDetail,
    Draggable,
    Container,
    ColorPicker,
    HowItWorks,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => {
    return {
      selectedChannel: {} as Channel,
      shouldShowChanelDetail: false,
      newChannel: {} as Channel,
      channels: [] as Channel[],
      currentChannelIntId: 0 as number,
      invalidFields: [] as string[],

      currentTypeIntId: 0 as number,
      isPaidMediaInputEnabled: true as boolean,
      isTacticFlaggingInputEnabled: true as boolean,

      initiativesChannelName: Channel.CHANNEL_NAME_INITIATIVES as string,

      universalFieldEditingRows: [] as any[],
      newUniversalFieldName: '' as string,
      newUniversalFieldType: '' as string,
      fieldOptions: {
        string: 'Text',
        number: 'Number',
        url: 'Link',
        image: 'Image',
        richtext: 'Rich Text',
      },
    }
  },
  mounted: function () {
    this.newChannel = new Channel()
  },
  computed: {
    isCreateChannel(): boolean {
      return this.currentChannelIntId != 0 ? false : true
    },
    currentChannel(): Channel {
      // const returnChannel = this.$store.getters.currentPlan.channels.find(
      //   (channel) => channel.id.intID == this.currentChannelIntId
      // )

      return this.newChannel
    },
    currentType(): TacticType {
      const returnType = this.allTypesInChannel.find(
        (type) => type.id.intID == this.currentTypeIntId
      )
      return returnType || new TacticType()
    },
    currentFields(): TacticField[] {
      return this.currentType.tacticFields
    },
    currentUniversalFields(): TacticField[] {
      return this.plan.tacticFields
    },
    tacticFieldTypes(): {}[] {
      return Object.keys(TacticFieldType).map((key) => {
        return {
          code: key,
          label: this.fieldOptions[key],
        }
      })
    },
    allTypesInChannel(): TacticType[] {
      return this.currentChannel.tacticTypes
    },
    visibleTypesInChannel(): TacticType[] {
      return this.currentChannel.tacticTypes.filter(
        (type) =>
          type.name.toLowerCase() !== this.$store.getters.monthlyFocusTypeName
      )
    },
    allPlatformsForPlan(): TacticPlatform[] {
      return this.$store.getters.currentPlan.tacticPlatforms.filter(
        (platform) => !platform.isNested
      )
    },
    allPlatformsForType(): TacticPlatform[] {
      return this.currentType.tacticPlatforms
    },
    shouldShowCurrentMonthlyFocus: {
      get(): boolean {
        return this.currentChannel.useMonthlyFocusType
      },
      set(newValue: boolean) {
        this.currentChannel.useMonthlyFocusType = newValue
      },
    },
    socialChannelName(): string {
      return Channel.CHANNEL_NAME_SOCIAL
    },
    isPaidMedia: {
      get(): boolean {
        return this.currentChannel.isPaidMedia
      },
      set(value: boolean) {
        this.isTacticFlaggingInputEnabled = !value
        this.currentChannel.isPaidMedia = value
      },
    },
    enableTacticFlagging: {
      get(): boolean {
        return this.currentChannel.enableTacticFlagging
      },
      set(value: boolean) {
        this.isPaidMediaInputEnabled = !value
        this.currentChannel.enableTacticFlagging = value
      },
    },

    plan(): Plan {
      return this.$store.getters.currentPlan
    },
    isLeadPlan(): boolean {
      return this.plan.plans.length ? true : false
    },
    isNestedPlan(): boolean {
      return this.$store.getters.currentPlan.parentId.intID !== 0 ? true : false
    },
    canUserManagePlanChannels(): boolean {
      return (
        this.$store.getters.currentPlanPermissionLevel <= Role.LEVEL_PLAN_ADMIN
      )
    },
    filteredChannels(): Channel[] {
      const localCurrentChannels = [...this.$store.getters.currentPlan.channels]
      return localCurrentChannels.sort((a, b) => {
        if (a.orderIndex === b.orderIndex) {
          return 0
        }
        return a.orderIndex > b.orderIndex ? 1 : -1
      })
    },
    tacticSummaryOn: {
      get: function (): boolean {
        return this.$store.getters.currentPlan.isTacticSummaryFieldEnabled
      },
      set: function (newValue: boolean) {
        // do nothing
      },
    },
    tacticSummaryOff: {
      get: function (): boolean {
        return !this.$store.getters.currentPlan.isTacticSummaryFieldEnabled
      },
      set: function (newValue: boolean) {
        // do nothing
      },
    },
    isCurrentUserSuperUser(): boolean {
      return this.$store.getters.currentUser.isSuperUser
    }
  },
  methods: {
    onUniversalFieldRowEditInit() {
      // Init field row edit
    },
    onUniversalFieldRowEditSave(event) {
      const thisField = new TacticField(
        event.newData.id, 
        event.newData.creatorId, 
        event.newData.name,
        TacticFieldType[event.newData.type],
        event.newData.description, 
        event.newData.helpText, 
        event.newData.options, 
        event.newData.orderIndex, 
        event.newData.label
      )
      this.newUniversalFieldName = ''
      this.newUniversalFieldType = ''

      this.$store.getters.services.channels
        .updateTacticField(thisField)
        .then((returnedTacticField) => {
          const planField = this.plan.tacticFields.find((field) => field.id.intID === returnedTacticField.id.intID)
          if(planField){
            planField.name = returnedTacticField.name
          }
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Custom field updated.',
          })
        })
        .catch((error)=>{
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.warn,
            summary: 'Error updating custom field.',
            detail: error,
          })
        })
    },
    onUniversalFieldDelete($event, fieldData) {
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this Custom Field? All associated data related with this field will be removed from Tactics.`,
        target: $event.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.channels
            .deleteTacticField(fieldData.id.intID)
            .then(() => {
              this.plan.tacticFields = this.plan.tacticFields.filter(
                (field) => field.id.intID !== fieldData.id.intID
              )

              this.$store.getters.services.plans
                .update(this.plan)
                .then(() => {
                  // Success
                })
                .catch((error) =>
                  Vue.prototype.$toast.add({
                    severity: AlertMessageSeverity.warn,
                    summary: 'Error removing custom field from tactic type.',
                    detail: error,
                  })
                )
            })
            .catch((error) =>
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.warn,
                summary: 'Error deleting custom field.',
                detail: error,
              })
            )
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
    addUniversalField() {      
      if (!this.newUniversalFieldName) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must give a name to the field first',
        })
        return
      }
      if (!this.newUniversalFieldType) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'You must select a type',
        })
        return
      }

      const newTacticField = new TacticField(
        new ID(),
        this.$store.getters.currentUser?.id,
        this.newUniversalFieldName,
        this.newUniversalFieldType['code'],
        '',
        '',
        [],
      )

      this.$store.getters.services.channels
        .createTacticField(newTacticField)
        .then((returnedTacticField) => {
          this.plan.tacticFields.push(returnedTacticField)
          this.$store.getters.services.plans.update(this.plan).then(
            ()=>{
              this.newUniversalFieldName = ''
              this.newUniversalFieldType = ''
            },
            (error)=>{
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary:
                  'Error creating universal tactic field.',
                detal: error,
              })
            }
          )
        })
    },
    getTacticFieldTypeLabelForCode(code: string): string {
      const thisCode = this.tacticFieldTypes.find((option) => option['code'] == code)
      return thisCode ? thisCode['label'] : ''
    },
    canCurrentUserEditChannel(channel): boolean {
      if(this.$store.getters.currentUserRole.canEditAllChannels){
        return true
      }
      return this.$store.getters.currentUserRole.channels.find(
        (channelId) => channelId.intID === channel.id.intID
      )
        ? true
        : false
    },
    refreshView() {
      this.$emit('ChannelChanged')
    },
    handleDeleteChannel(channelId: ID, event) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure?',
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.channels
            .delete([channelId.intID])
            .then(() => {
              this.$store.dispatch('refreshCurrentPlan')
            })
        },
        reject: () => {
          // Reject item delete
        },
      })
    },
    handleEditChannel(channel) {
      //TODO: use $store currentChannel instead of local data property?
      if (channel) {
        this.selectedChannel = channel
      } else {
        this.selectedChannel = new Channel()
      }

      this.shouldShowChanelDetail = true
    },
    areChannelFieldsValid() {
      let isFormValid = true
      this.invalidFields = []
      if (
        this.currentChannel.name === '' ||
        this.currentChannel.name.toLowerCase() ===
          Channel.CHANNEL_NAME_INITIATIVES.toLowerCase() ||
        this.currentChannel.name.toLowerCase() ===
          Channel.CHANNEL_NAME_SUBSCRIPTIONS.toLowerCase()
      ) {
        isFormValid = false
        this.invalidFields.push('channel-name')
      }
      return isFormValid
    },
    addChanel() {
      // Validate fields
      if (!this.areChannelFieldsValid()) {
        return false
      }

      return new Promise((resolve, reject) => {
        this.currentChannel.planId = this.$store.getters.currentPlan.id
        this.currentChannel.orderIndex =
          this.$store.getters.currentPlan.channels.length

        if (this.isCreateChannel) {
          this.currentChannel.creatorId = this.$store.getters.currentUser.id
          this.$store.getters.services.channels
            .create(this.currentChannel)
            .then(
              (returnedChannel) => {
                // Add new channel to UserRoles in plan so plan settings team displays correct channel edit rights
                const currentPlanChannels =
                  this.$store.getters.currentPlan.channels.filter(
                    (channel) =>
                      channel.name !== Channel.CHANNEL_NAME_INITIATIVES &&
                      ((this.isLeadPlan && !channel.isNested) ||
                        (!this.isLeadPlan &&
                          (channel.isLead || channel.isNested)) ||
                        (!channel.isLead && !channel.isNested))
                  )
                this.$store.getters.currentPlan.userRoles.forEach((userRole) => {
                  if (userRole.channels.length >= currentPlanChannels.length) {
                    userRole.channels.push(returnedChannel.id)
                  }
                })

                // Add rights for new channel to current user's UserRole
                this.$store.getters.currentUserRole.channels.push(
                  returnedChannel.id
                )
                this.$store.getters.currentUserRole.accountId = this.$store.getters.currentAccount.id.clone()
                this.$store.getters.services.users
                  .updateUserRole(this.$store.getters.currentUserRole)
                  .then(
                    () => {
                      // Success
                    },
                    (error) => {
                      Vue.prototype.$toast.add({
                        severity: AlertMessageSeverity.error,
                        summary:
                          'Error adding edit rights for new channel to user.',
                        detal: error,
                      })
                    }
                  )

                // Add channel to plan
                this.$store.getters.currentPlan.channels.push(returnedChannel)

                // Reset UI
                returnedChannel.opened = true
                this.newChannel = new Channel()
                this.currentChannelIntId = 0
                this.$store.getters.currentUserRole.channels.push(
                  returnedChannel.id
                )

                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: 'Channel created.',
                  life: 3000,
                })

                resolve(returnedChannel)
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: 'Error creating channel.',
                  detail: error,
                })
                reject(error)
              }
            )
        } else {
          this.$store.getters.services.channels
            .update(this.currentChannel)
            .then(
              (returnedChannel) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.success,
                  summary: 'Channel updated.',
                  life: 3000,
                })
                this.$store.dispatch('refreshCurrentPlan')
                this.currentChannel.name = ''
                resolve(returnedChannel)
              },
              (error) => {
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: 'Error updating channel.',
                  detail: error,
                })
                reject(error)
              }
            )
        }
        this.$emit('ChannelChanged')
      })
    },
    onChannelDragEnd(event) {
      const localFilteredChannels = [...this.filteredChannels]
      localFilteredChannels.splice(
        event.addedIndex,
        0,
        localFilteredChannels.splice(event.removedIndex, 1)[0]
      )
      localFilteredChannels.forEach((channel, index) => {
        channel.orderIndex = index
        this.$store.getters.services.channels.update(channel) // TODO: reduce number of requests by updating all channels with one request
      })
    },
    handleToggleTacticSummary(value) {
      // Persist change on all channel objects
      this.$store.getters.currentPlan.channels.forEach((channel) => {
        channel.isSummaryEnabled = value
        this.$store.getters.services.channels.update(channel)
      })
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.integration-data-table {
  border: 0.01rem solid black;

  th,
  td {
    border: 0.01rem solid black;
    padding: 0.5rem;
  }
  th {
    @include font-bold;
    font-size: 1.2rem;
  }
}
.data-column-40 {
  width: 40%;
}
.data-column-10 {
  width: 10%;
}
</style>