<template>
  <div class="central-content">
    <Checkbox
      :id="`calendar-${index}`"
      v-model="calendar.selected"
      :binary="true"
      class="p-mr-2 custom__checkbox"
    />
    <div class="calendar__trigger calendar-label" v-if="!calendar.opened">
      {{ calendar.name }}
      <template v-if="$store.getters.currentUser.isSuperUser">
        <span class="id-value"> ({{ calendar.id.intID }})</span>
      </template>
    </div>
    <div v-else class="custom__opened">
      <div class="calendar-field">
        <div>
          <label :for="`calendar-${calendar.id.intID}-name`">Name</label>
        </div>
        <InputText
          :id="`calendar-${calendar.id.intID}-name`"
          type="text"
          v-model="calendar.editValues.name"
          v-if="isEditable"
        />
        <h3 v-else class="text-detail">{{ calendar.editValues.name }}</h3>
        <span class="">
          <i
            class="pi pi-trash trash-icon delete-icon"
            @click="(e) => handleCalendarDelete(e, calendar, calendar.id.intID)"
            v-if="isEditable"
          />
          <i
            class="pi pi-check save-icon"
            @click="() => handleCalendarEdit(calendar)"
            v-if="isEditable"
          />
        </span>
      </div>
      <div class="calendar-field">
        <div><label for="">Description</label></div>
        <TextArea
          type="text"
          v-model="calendar.editValues.description"
          v-if="isEditable"
        />
        <h3 v-else class="text-detail">
          {{ calendar.editValues.description }}
        </h3>
      </div>
      <div class="calendar-field" v-if="isEditable">
        <div><label for="">Public address in iCal format</label></div>
        <p class="calendar-path">
          {{ calendar.calendarPath }}
        </p>
      </div>
    </div>
    <span
      :class="`calendar__arrow pi pi-chevron-${
        calendar.opened ? 'down' : 'right'
      }`"
      @click="() => (calendar.opened = !calendar.opened)"
    ></span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import Subscription from '@/models/Subscription'

Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('TextArea', TextArea)

export default Vue.extend({
  name: 'PlanSettingsSubscriptionDetail',
  props: {
    calendar: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isEditable: {
      type: Boolean,
    },
  },
  methods: {
    handleCalendarEdit(calendar) {
      calendar.name = calendar.editValues.name
      calendar.description = calendar.editValues.description
      calendar.organization = calendar.editValues.organization
      this.$store.getters.services.accounts
        .updateSubscription(calendar)
        .then(() => {
          calendar.opened = false
        })
    },
    handleCalendarDelete(e, calendar, calendarId) {
      e.stopPropagation()
      this.$confirm.require({
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        message: `Are you sure you want to delete this custom Calendar of Interest?`,
        target: e.currentTarget,
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.accounts
            .deleteSubscription(calendarId)
            .then(() => {
              calendar.opened = false
              this.$emit('deleteSubscription',calendarId)
            })
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      })
    },
  },
})
</script>