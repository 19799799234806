<template>
  <div class="view-wrapper">
    <ViewHeader title="Plans"></ViewHeader>
    <ViewMain>
      <DataTable
        :value="currentPlans"
        class="p-mb-4"
      >
        <Column columnKey="name" header="Name" sortable>
          <template #body="slotProps">
            <div
              class="p-d-flex p-jc-between"
              :class="!!slotProps.data.parentId.intID ? 'p-ml-5' : ''"
            >
              <div class="p-d-flex p-ai-center">{{ slotProps.data.name }}</div>
              <Button
                label="Launch Plan"
                @click="launchPlan(slotProps.data.id)"
                :disabled="!isAccountActive"
              ></Button>
            </div>
          </template>
        </Column>
        <template #loading>
          <h3>Loading plans...</h3>
        </template>
        <template #empty>
          <h3>No plans available.</h3>
        </template>
      </DataTable>
      <div v-if="!isAccountActive" class="inactive-account-overlay">
        <h2 v-if="this.$store.getters.currentAccount.showChargebeeUI">Your Subscription has expired. Select "Subscriptions" under your profile in bottom left corner to reactivate.</h2>
        <h2 v-else>Your Subscription has expired. Select "Help" in bottom left corner and "Contact Us" to send an email regarding reactivation.</h2>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ID from '@/models/ID'

import Dialog from 'primevue/dialog'

Vue.component('Column', Column)
Vue.component('DataTable', DataTable)
Vue.component('Dialog', Dialog)

export default Vue.extend({
  name: 'PlanList',
  components: {
    ViewHeader,
    ViewMain,
  },
  computed: {
    currentPlans() {
      return this.$store.getters.allPlans.filter((plan) => {
        if (
          plan.userRoles.filter(
            (userRole) =>
              userRole.user.id.intID == this.$store.getters.currentUser.id.intID
          ).length
        ) {
          return true
        }
        return false
      })
    },
    isAccountActive(): boolean {
      return this.$store.getters.currentAccount.isActive
    },
  },
  methods: {
    launchPlan(planId: ID) {
      this.$store.dispatch('updateCurrentPlanById', planId).then(() => {
        this.$router.push(
          `/account/${this.$store.getters.currentAccount.id.intID}/plan/${planId.intID}/calendar/month`
        )
      })
    },
  },
})
</script>

<style lang="scss">
.plan-settings-tutorial {
  .plan-settings-modal-header-tutorial {
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    align-items: center;
    padding: 5.6rem 2.4rem 1rem 1.6rem;
    background-color: #f9f9f9;

    h2 {
      margin-bottom: 0;
    }
    .p-button-outlined {
      z-index: 1; //Fixing issue with other buttons overlapping outlined buttons on initial render
    }
  }
  .plan-settings-modal-content {
    padding: 1.6rem;
  }
  .p-dialog {
    // overflow-y: scroll;
    overflow-y: visible;
    max-height: 100vh;
    width: 80%;
  }
  & > .p-dialog-content {
    padding: 0;
  }
}
.inactive-account-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;

  h2 {
    position: absolute;
    top: calc(50vh - 7.2rem);
  }
}
</style>
