import UserRole from './UserRole'
import ID from './ID'
import Plan from './Plan'
import Subscription from './Subscription'
import User from './User'

export enum PlanRatingPointsType {
  'trp' = 'trp',
  'grp' = 'grp',
}

export default class Account {
  constructor(
    public id: ID = new ID(),
    public ownerId: ID = new ID(),
    public contactCompanyName: string = '',
    public contactFirstName: string = '',
    public contactLastName: string = '',
    public contactPhone: string = '',
    public contactEmail: string = '',
    public contactAddress1: string = '',
    public contactAddress2: string = '',
    public contactCity: string = '',
    public contactState: string = '',
    public contactZip: string = '',
    public billingAddress1: string = '',
    public billingCity: string = '',
    public billingState: string = '',
    public billingZip: string = '',
    public billingPaymentId: string = '',
    public plans: Plan[] = [],
    public userRoles: UserRole[] = [],
    public users: User[] = [],
    public subscriptions: Subscription[] = [],
    public source: string = '',
    public showChargebeeUI: boolean = false,
    public accountActive: boolean = true,
  ) {}

  public static SOURCE_CHARGEBEE = 'chargebee'

  public get key() {
    return 'account-' + this.id.intID.toString()
  }

  public get userIds(): number[] {
    return this.users.map((user) => user.id.intID)
  }

  public get isActive(): boolean {
    return this.accountActive
  }

  public static fromResponseObject(obj): Account {
    if (!obj) return new Account()

    const userRoles = [] as UserRole[]
    if (obj.userRoles && obj.userRoles.length) {
      obj.userRoles.forEach((element) => {
        userRoles.push(UserRole.fromResponseObject(element))
      })
    }

    const plans = [] as Plan[]
    if (obj.plans && obj.plans.length) {
      obj.plans.forEach((element) => {
        const thisPlan = Plan.fromResponseObject(element)
        thisPlan.userRoles = userRoles.filter((userRole)=>userRole.planId.intID === element.id) // Add UserRole objects to Plan objects
        plans.push(thisPlan)
      })
    }

    const users = [] as User[]
    if (obj.users && obj.users.length) {
      obj.users.forEach((element) => {
        users.push(User.fromResponseObject(element))
      })
    }

    const subscriptions = [] as Subscription[]
    if (obj.subscriptions && obj.subscriptions.length) {
      obj.subscriptions.forEach((element) => {
        subscriptions.push(Subscription.fromResponseObject(element))
      })
    }

    return new Account(
      ID.fromResponseObject(obj.id, 'accounts'),
      obj.owner ? ID.fromResponseObject(obj.owner, 'users') : new ID(),
      obj.contactCompanyName ? obj.contactCompanyName : '',
      obj.contactFirstName ? obj.contactFirstName : '',
      obj.contactLastName ? obj.contactLastName : '',
      obj.contactPhone ? obj.contactPhone : '',
      obj.contactEmail ? obj.contactEmail : '',
      obj.contactAddress1 ? obj.contactAddress1 : '',
      obj.contactAddress2 ? obj.contactAddress2 : '',
      obj.contactCity ? obj.contactCity : '',
      obj.contactState ? obj.contactState : '',
      obj.contactZip ? obj.contactZip : '',
      obj.billingAddress1 ? obj.billingAddress1 : '',
      obj.billingCity ? obj.billingCity : '',
      obj.billingState ? obj.billingState : '',
      obj.billingZip ? obj.billingZip : '',
      obj.billingPaymentId ? obj.billingPaymentId : '',
      plans,
      userRoles,
      users,
      subscriptions,
      obj.source ? obj.source : '',
      obj.showChargebeeUI ? obj.showChargebeeUI : false,
      obj.accountActive ? obj.accountActive : false,
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      owner: this.ownerId.intID == 0 ? null : this.ownerId.apiID,
      contactCompanyName: this.contactCompanyName,
      contactFirstName: this.contactFirstName,
      contactLastName: this.contactLastName,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      contactAddress1: this.contactAddress1,
      contactAddress2: this.contactAddress2,
      contactCity: this.contactCity,
      contactState: this.contactState,
      contactZip: this.contactZip,
      billingAddress1: this.billingAddress1,
      billingCity: this.billingCity,
      billingState: this.contactState,
      billingZip: this.billingZip,
      billingPaymentId: this.billingPaymentId,
      plans: this.plans.map((plan) => plan.id.apiID),
      users: this.users.map((user) => user.id.apiID),
    }
  }
}
