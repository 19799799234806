import TacticField from './TacticField'
import Tactic from './Tactic'
import TacticPlatform from './TacticPlatform'
import ID from './ID'
import {rrulestr} from 'rrule'
import {isSameDay, differenceInMinutes, addMinutes} from 'date-fns'

export default class TacticType {
  public opened: boolean
  public editing: boolean
  public newPlatform: string
  public newPlatforms: TacticPlatform[]
  public editedTacticTypeName: string
  public newTacticField

  constructor(
    public id: ID = new ID(),
    public creatorId: ID = new ID(),
    public name: string = '',
    public description: string = '',
    public tacticFields: TacticField[] = [],
    public tacticPlatforms: TacticPlatform[] = [],
    public channelId: ID = new ID(),
    public shouldHideTacticsFromNestedPlans: boolean = false,
    public isVisibleCustomTacticType: boolean = false,
    public orderIndex: number = 0,
    public shouldTacticsAllowMultiplePlatforms: boolean = false,

    public tactics: Tactic[] = [], //Populated on front-end
    public channelName: string = '', //Populated on front-end
    public abbreviatedPlanName: string = '' //Populated on front-end
  ) {
    this.opened = false
    this.editing = false
    this.newPlatform = ''
    this.newPlatforms = []
    this.editedTacticTypeName = name
    this.newTacticField = {
      name: '',
      type: '',
      description: '',
      helpText: '',
    }
  }

  public get key() {
    return 'tactic-type-' + this.id.intID.toString()
  }

  public get hasTactics(): boolean {
    if (this.tactics.length) {
      return true
    }
    return false
  }

  public getTacticsWithClonesForDateRange(dateRange: Date[]): Tactic[]{
    let clonesArray = [] as Tactic[]
    this.tactics.forEach((tactic) => {
      if (!tactic.rrule) {
        return
      }
      const timezoneOffset = new Date().getTimezoneOffset()
      const diff = differenceInMinutes(tactic.endDate, tactic.startDate)
      const rules = rrulestr(tactic.rrule)

      // Set valid recurrence end date if tactic repeats indefinitely
      const currentDate = new Date()
      let thisRecurrenceEndDate = new Date()
      thisRecurrenceEndDate.setFullYear(currentDate.getFullYear()+1000)
      thisRecurrenceEndDate.setHours(23,59,59,999)
      if(tactic.recurrenceEndDate != null){
        thisRecurrenceEndDate = tactic.recurrenceEndDate
      }

      // Adjust date range to account for timezone offsets when using rrulestr.between() to find recurrence dates below
      // Instances outside of actual date range will be filtered out by date range tests on each view
      const adjustedStartDate = new Date(dateRange[0])
      adjustedStartDate.setDate(adjustedStartDate.getDate() - 1)
      const adjustedEndDate = new Date(dateRange[1])
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1)

      // Find recurrence dates
      const recurrenceDates = rules
        .between(
          adjustedStartDate,
          adjustedEndDate
        )
        .filter((recurrenceStartDate) => {
          // Filter out original tactic and any clones prior to it, also filter out any tactics after recurrence end date
          return recurrenceStartDate > tactic.startDate && recurrenceStartDate < thisRecurrenceEndDate
        })

      // Add clones to array
      if (recurrenceDates.length) {
        clonesArray = clonesArray.concat(recurrenceDates.map((recurrenceDate) => {
          // Add local timezone offset to recurrenceDate
          recurrenceDate.setHours(recurrenceDate.getHours() + (timezoneOffset / 60))

          return {
            ...tactic,
            id: new ID(Number(tactic.id.intID.toString() + recurrenceDate.getTime().toString())),
            startDate: recurrenceDate,
            endDate: addMinutes(recurrenceDate, diff),
            isRecurringEventClone: true,
            originalTacticID: tactic.id.clone(),
          } as Tactic
        }))
      }
    })
    return this.tactics.concat(clonesArray)
  }

  public static fromResponseObject(obj): TacticType {
    if (!obj) return new TacticType()

    const tacticFields = [] as TacticField[]
    if(obj.tacticFields){
      obj.tacticFields.forEach((element) => {
        if (typeof element == 'string') {
          tacticFields.push(
            new TacticField(ID.fromResponseObject(element, 'tactic_fields'))
          )
        } else {
          tacticFields.push(TacticField.fromResponseObject(element))
        }
      })
    }

    const tacticPlatforms = [] as TacticPlatform[]
    if(obj.tacticPlatforms){
      obj.tacticPlatforms.forEach((element) => {
        if (typeof element == 'string') {
          tacticPlatforms.push(
            new TacticPlatform(ID.fromResponseObject(element, 'tactic_platforms'))
          )
        } else {
          tacticPlatforms.push(TacticPlatform.fromResponseObject(element))
        }
      })
    }

    return new TacticType(
      ID.fromResponseObject(obj.id, 'tactic_types'),
      ID.fromResponseObject(obj.creator, 'users'),
      obj.name,
      obj.description,
      tacticFields,
      tacticPlatforms,
      ID.fromResponseObject(obj.channel, 'channels'),
      obj.shouldHideTacticsFromNestedPlans,
      obj.isVisibleCustomTacticType,
      obj.orderIndex,
      obj.shouldTacticsAllowMultiplePlatforms
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      creator: this.creatorId.intID == 0 ? null : this.creatorId.apiID,
      name: this.name,
      description: this.description,
      tacticFields: this.tacticFields.map((field) => {
        return field.id.apiID
      }),
      tacticPlatforms: this.tacticPlatforms.map((platform) => {
        return platform.id.apiID
      }),
      channel: this.channelId.intID == 0 ? null : this.channelId.apiID,
      shouldHideTacticsFromNestedPlans: this.shouldHideTacticsFromNestedPlans,
      isVisibleCustomTacticType: this.isVisibleCustomTacticType,
      orderIndex: this.orderIndex,
      shouldTacticsAllowMultiplePlatforms: this.shouldTacticsAllowMultiplePlatforms
    }
  }
}
