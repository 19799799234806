<template>
  <div
    class="logged-out-container p-d-flex p-flex-column p-jc-center p-ai-center"
  >
    <form class="logged-out-form" @submit.prevent="onFormSubmit()">
      <h1>Sign In</h1>
      <div>
        <div class="p-field">
          <label for="log_in_email">Email Address</label>
          <InputText
            id="log_in_email"
            type="email"
            v-model="emailAddress"
            autocapitalize="none"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @blur="onEmailFieldBlur"
          />
        </div>
        <div class="p-field">
          <label for="log_in_password">Password</label>
          <Password
            id="log_in_password"
            :feedback="false"
            v-model="password"
            autocapitalize="none"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
          />
        </div>
      </div>
      <Button type="submit" class="p-mb-4" label="Sign In" :disabled="!isSubmitEnabled" />
      <div class="forgot-password-container">
        <router-link to="/forgot-password">Forgot Password?</router-link>
      </div>
    </form>
    <!-- TODO: remove old SSO system? -->
    <div class="sso-container" v-if="shouldShowSso">
      <Button class="p-mt-4 p-button-outlined" label="Sign In With SAML" @click="onSamlSignInClick" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import UserSetup from '@/models/UserSetup'
import AlertMessage, {AlertMessageSeverity} from '@/models/AlertMessage'

Vue.component('InputText', InputText)
Vue.component('Password', Password)
Vue.component('Button', Button)

export default Vue.extend({
  name: 'LogIn',
  data: () => {
    return {
      emailAddress: '' as string,
      password: '' as string,
      emailPattern: new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}$/),
      isSubmitEnabled: true,
    }
  },
  computed: {
    isFormValid(): boolean {
      // if (this.emailPattern.test(this.emailAddress) && this.password !== '') {
      //   return true
      // }
      // return false

      // Temporarily removing email pattern validation to prevent login issues with test email addresses - JV 07.18.22
      return true
    },
    shouldShowSso(): boolean {
      return this.$route.query.sso ? true : false
    }
  },
  methods: {
    onEmailFieldBlur: function() {
      this.isSubmitEnabled = false
      const emailAddressDomain = this.emailAddress.slice(this.emailAddress.indexOf('@')+1)
      if(emailAddressDomain === ''){
        return 
      }
      this.$store.getters.services.authentication.getSsoStatus(emailAddressDomain).then(
        (response)=>{
          if(response.length){
            // Redirect to first SSO option if available
            window.location.href = response[0].redirectUrl
          }
          this.isSubmitEnabled = true
        },
        (error)=>{
          console.warn('Error getting SSO status for email domain', error)
          this.isSubmitEnabled = true
        }
      )
    },
    onFormSubmit: function () {
      if (!this.isFormValid) {
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.error,
          summary: 'Please complete all fields.',
          life: 3000,
        })
        return
      }

      this.$store
        .dispatch('login', new UserSetup(this.emailAddress, this.password))
        .then(() => {
          //Re-route to most recent view with most recent plan if available, else route to dashboard
          if (
            this.$store.getters.currentPlan &&
            this.$store.getters.currentPlan.id.intID != 0 &&
            this.$store.getters.mostRecentCoreView
          ) {
            //TODO: may need to remove this as refreshing page produces potentially unexpected routing
            this.$router.replace(
              `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$store.getters.currentPlan.id.intID}/${this.$store.getters.mostRecentCoreView}`
            )
          } else {
            this.$router.push('/').catch(err=>{
              // Swallow redundant navigation error
            })
          }
        },
        (error)=>{
          // TODO: if user not found error, reroute to '/sign-in/error/'
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'The email and password you entered do not match our records. Please double-check and try again.',
          })
        })
    },
    onSamlSignInClick(){
      const apiBaseUrl: string = process.env.VUE_APP_API_BASE_URL
      window.location.href = `${apiBaseUrl.length > 5 ? apiBaseUrl.slice(0, apiBaseUrl.indexOf('/api/')) : 'https://devapi.annumapp.com'}/saml/login?sso=${this.$route.query.sso}`
    }
  },
})
</script>

<style lang="scss">
.logged-out-container {
  margin-top: 2rem;

  .p-field,
  .p-field > label {
    text-align: left;
  }
  .p-field .p-component {
    width: 100%;
  }
}
</style>